import axios from "axios";

// default
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

if (localStorage.getItem('token')) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem('token');
}

// intercepting to capture errors
axios.interceptors.response.use(
  function (response: any) {
    return response.data ? response.data : response;
  },
  function (error: any) {
    let message;
    switch (error?.response?.status) {
      case 401:
        localStorage.clear();
        window.location.href = "/auth-login";
        break;
      default:
        message = error.message;
    }
    return error?.response;
  }
);

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: any) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */
  get = (url: string, params?: {}) => {
    return axios.get(url, params);
  };

  /**
   * post given data to url
   */
  create = (url: string, data?: {}) => {
    return axios.post(url, data);
  };

  export = (url: string, data?: {}) => {
    return axios.post(url, data, { responseType: 'blob' });
  };
  /**
   * Updates data
   */
  update = (url: string, data?: {}) => {
    return axios.put(url, data);
  };

  /**
   * Delete
   */
  delete = (url: string, config?: {}) => {
    return axios.delete(url, { ...config });
  };

  /*
   file upload update method
  */
  updateWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }
    // const config = {
    //   headers: {
    //     ...axios.defaults.headers,
    //     "content-type": "multipart/form-data",
    //   },
    // };
    // return axios.put(url, formData, config);
  };

  /*
   file upload post method
   */
  createWithFile = (url: string, formData: any) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return axios.post(url, formData, config);
  };
};

const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser };
