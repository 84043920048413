import { Button } from "antd";
import { is } from "date-fns/locale";
import styled from 'styled-components';


interface FilterRangeButton {
    onClick: any;
    title: string
    isActive: boolean;
}

const CustomButton = styled.div<{ active: boolean }>`
  background-color: ${(props) => (props.active ? '#1677ff' : '#fff')} !important; 
  color: ${(props) => (props.active ? '#fff' : '#000')} !important;
  border: none; 
  border-radius: 4px; 
  padding: 8px 8px; 
  font-size: 14px; 
  line-height: 1; 
  transition: all 0.3s ease;
  margin-bottom: 4px;
  cursor: pointer;
  width: 100%;
  display: flex;
    justify-content: center;
    align-items: center;

  &:hover {
    background-color: ${(props) => (props.active ? '#1677ff' : '#EAECF0')} !important; 
    color: ${(props) => (props.active ? '#fff' : '#000')} !important;
  }
`;

const FilterRangeButton = (props: FilterRangeButton) => {
    return (
        <CustomButton
            onClick={props.onClick}
            active={props.isActive}
        >
            {props.title}
        </CustomButton>
    );
};

export default FilterRangeButton;