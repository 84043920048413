import React, { useEffect, useState } from "react";
import moment from "moment";

// api
import {
  getMessageList,
} from "../../../../api/index";

// components
import {
  Spin
} from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import InfiniteScroll from "react-infinite-scroll-component";
import MessageList from "./MessageList";

interface ConversationProps {
  objConversation: any

}
const MessagesModal = ({objConversation}: ConversationProps) => {
  const [msgList, setMsgList] = useState<any>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);

  useEffect(() => {
    if (msgList === null && objConversation?.conversationId) {
      getMsgListData({ page: 1, limit: 20 });
    }
  }, [objConversation]);

  const fetchMoreData = () => {
    if (msgList?.total === msgList?.items?.length) {
      setHasMore(false);
      return;
    }
    setTimeout(() => {
      getMsgListData({ page: msgList?.page + 1, limit: 20 });
    }, 300);
  };

  const getMsgListData = async (data: any) => {
    try {
      const response: any = await getMessageList(objConversation?.conversationId, { page: data?.page, limit: data?.limit });
      if (response?.data?.items) {
        const arr = msgList?.items && data.page !== 1 ? [...msgList?.items, ...response.data.items] : response.data.items;
        setMsgList({
          total: response.data.total,
          page: data.page,
          items: arr,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const renderGroupedDateMessages = () => {
    const groupedMessages = msgList?.items?.length > 0 && msgList?.items?.reduce((groups: any, message: any) => {
      const date = moment(message.createdAt).format("DD/MM/YYYY");
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});
    const groupedMessagesArray = Object.entries(groupedMessages).map(([date, messages]) => ({
      date,
      messages
    }));
    return (
      groupedMessagesArray?.sort((a: any, b: any) => moment(b.date, 'DD/MM/YYYY').valueOf() - moment(a.date, 'DD/MM/YYYY').valueOf())?.map((group: any, index: number) => {
        return (
          <div key={index}>
            <div className="chat-day-title">
              <span className="title">
                {
                  group.date == moment().format('DD/MM/YYYY') ?
                    'Hôm nay' :
                      group.date == moment().subtract(1, 'days').format('DD/MM/YYYY') ?
                        'Hôm qua' :
                        group.date
                }
              </span>
            </div>
            {
              renderGroupedTimeMessages(group.messages)
            }
          </div>
        );
      })
    );
  };
  const renderGroupedTimeMessages = (messageList: any) => {
    const groupedMessages = messageList?.length > 0 && messageList.reduce((groups: any, message: any) => {
      const date = new Date(moment(message.createdAt).valueOf());
      const hour = date.getHours();
      if (!groups[hour]) {
        groups[hour] = [];
      }
      groups[hour].push(message);
      return groups;
    }, {});
    const groupedMessagesArray = Object.entries(groupedMessages).map(([hour, messages]) => ({
      hour,
      messages
    }));
    return (
      groupedMessagesArray.map((group: any, index: number) => {
        return (
          <div
            key={index}
            style={{
              marginBottom: index == groupedMessagesArray?.length - 1 ? 0 : 10,
            }}
          >
            <ul className="list-unstyled chat-conversation-list">
              {group.messages?.sort((a: any, b: any) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()).map((message: any, key: number) => {
                const isFromMe = message?.from.id == message?.channelId;
                return (
                  <MessageList
                    message={message}
                    key={key}
                    selectedConversation={objConversation}
                    isFromMe={isFromMe}
                  />
                );
              })}
            </ul>
            {
              group.messages[group.messages?.length - 1]?.from?.id !== objConversation?.conversationId ? (
                group.messages[group.messages?.length - 1]?.createdBy?.name ? (
                  ""
                ) : null
              ) : (
                group.messages[group.messages?.length - 1]?.from?.name ? (
                  <div
                    style={{
                      justifyContent: 'flex-start',
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: 44,
                    }}>
                    <div style={{fontSize: 11, color: '#495057bf'}}>
                      Người gửi: <span style={{fontSize: 11, color: '#1A1A1A'}}>{group.messages[group.messages?.length - 1]?.from?.name}</span>
                    </div>
                  </div>
                ) : null
              )
            }
          </div>
        );
      })
    );
  };
  return (
    <div
      className="chat-conversation p-3 p-lg-4 positin-relative"
      id="scrollableDiv"
      style={{
        overflowY: "auto",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column-reverse",
        paddingTop: 10,
        marginBottom: 0,
        paddingLeft: 0,
        height: '100%'
      }}
    >
      {
        msgList?.items?.length > 0 ? (
          <InfiniteScroll
            dataLength={msgList?.items?.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={msgList?.items?.length > 0 &&
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 30
              }}>
                <Spin indicator={<LoadingOutlined spin />} />
              </div>
            }
            style={{ display: 'flex', flexDirection: 'column-reverse' }}
            inverse={true}
            scrollableTarget="scrollableDiv"
          >
            {renderGroupedDateMessages()}
          </InfiniteScroll>
        ) : null
      }
    </div>
  );
};

export default MessagesModal;
