import React, { useEffect, useState, useCallback } from "react";
import { createSelector } from "reselect";
import {
  Row,
  Col,
} from "reactstrap";
import { Button, Dropdown, Modal, notification, message, Timeline, Tooltip, Spin } from 'antd';
import { Select } from 'antd';

import { Link } from "react-router-dom";
import classnames from "classnames";
import iconReceive from "../../../assets/images/icon-receive.png";
import iconSend from "../../../assets/images/icon-send.png";
import iconEmail from "../../../assets/images/email.png";
import { ExportOutlined, InfoCircleOutlined, MinusCircleOutlined, MailOutlined, EyeOutlined  } from '@ant-design/icons';

// hooks
import { useRedux } from "../../../hooks/index";

// actions
import { changeSelectedChat, changeSelectedConversation, chatsApiResponseSuccess, refreshConversation } from "../../../redux/actions";
import styled from "styled-components";
import { getDetailConversation, getDetailConversationFb, getTranslationsLanguages, onBanCustomer, readConversationId, updateTranslationsConversation } from "../../../api/conversations";
import { ChatsActionTypes } from "../../../redux/chats/types";
import { toast } from "react-toastify";
import { onTranslationsDetect } from "../../../api/chats";
import iconLink from "../../../assets/images/profileLink/link.svg";
import iconClock from "../../../assets/images/profileLink/clock.svg";
import './custom-modal.css';
import { getHistoryChat } from "../../../api/chatmenu";
import _ from "lodash";
import moment from "moment";

const { Option } = Select;

interface ProfileImageProps {
  selectedConversation: any;
  onCloseConversation: () => any;
  onOpenUserDetails: () => any;
}
const ProfileImage = ({
  onCloseConversation,
  onOpenUserDetails,
  selectedConversation,
}: ProfileImageProps) => {
  const fullName = selectedConversation?.from?.name;
  const shortName = selectedConversation?.from?.name?.charAt(0);

  const colors = [
    "bg-primary",
    "bg-danger",
    "bg-info",
    "bg-warning",
    "bg-secondary",
    "bg-pink",
    "bg-purple",
  ];
  const isOnline = true;
  const [color] = useState(Math.floor(Math.random() * colors.length));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataHistory, setDataHistory] = useState([]);
  const [activeTab, setActiveTab] = useState('history');
  const [hover, setHover] = useState(false);
  const showModalHistory = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getProfileLink = async () => {
    const resConversation = await getDetailConversationFb({ conversationId: selectedConversation.conversationId });
    if (resConversation?.data?.updated_time) {
      selectedConversation.lastMessageAt = resConversation?.data?.updated_time;
    }
      window.postMessage({
        fb: {
          taskId: +new Date(),
          type: 'MAKE_PROFILE_LINK',
          pageId: selectedConversation.pageId,
          threadId: selectedConversation.threadKey.split('_')[1],
          time: +new Date(selectedConversation.lastMessageAt),
        }
      }, '*');
    
  }

  const getHistoryChatData = async (conversationId: any) => {
    try {
      const response: any = await getHistoryChat({ conversationId: conversationId });
      if (response?.success) {
        setDataHistory(response?.data?.items)
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(fullName);
      message.success(<span>Copied <span style={{color:'rgb(16, 142, 233)'}}>{fullName}</span></span>);
    } catch (err) {
      message.error("Failed to copy text");
    }
  };

  useEffect(() => {
    if (selectedConversation?.conversationId !== undefined) {
      getHistoryChatData(selectedConversation?.conversationId)
    }
  }, [selectedConversation])

  return (
    <div className="d-flex align-items-center">
      <div className="flex-shrink-0 d-block d-lg-none me-2">
        <Link
          to="#"
          onClick={onCloseConversation}
          className="user-chat-remove text-muted font-size-24 p-2"
        >
          <i className="bx bx-chevron-left align-middle"></i>
        </Link>
      </div>
      <div className="flex-grow-1 overflow-hidden">
        <div className="d-flex align-items-center">
          <div
            onClick={getProfileLink}
            className={classnames(
              "flex-shrink-0",
              "chat-user-img",
              "align-self-center",
              "me-3",
              "ms-0",
              { online: isOnline }
            )}
          >
            {selectedConversation?.from?.avatar ? (
              <>
                <img
                  src={selectedConversation?.from?.avatar}
                  className="rounded-circle avatar-sm"
                  alt=""
                />
                <span
                  className={classnames(
                    "user-status",
                  )}
                ></span>
              </>
            ) : (
              <div className="avatar-sm align-self-center">
                <span
                  className={classnames(
                    "avatar-title",
                    "rounded-circle",
                    "text-uppercase",
                    "text-white",
                    colors[color]
                  )}
                >
                  <span className="username"> {shortName}</span>
                  <span className="user-status"></span>
                </span>
              </div>
            )}
          </div>
          <div className="flex-grow-1 overflow-hidden">
            <h2 onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              className="text-truncate mb-0 font-size-16"
              onClick={handleCopy}
              style={{ cursor: 'copy',color: '#3A3A3C' ,backgroundColor: hover ? '#DDE1E7' : '',width:'fit-content',padding:"3px 4px",borderRadius:6}}>
              {/* <Link
                to="#"
                onClick={onOpenUserDetails}
                className="user-profile-show text-reset"
              >
                {fullName}
              </Link> */}
              {fullName}
            </h2>
            <div style={{fontSize:11}}><EyeOutlined style={{fontSize:12}}/> Đã xem bởi {selectedConversation?.lastSeen?.user?.name}&nbsp;-&nbsp;
            {moment(selectedConversation?.lastSeen?.user?.createdAt).format("DD/MM/YYYY HH:mm")}</div>
            <div>
              <img src={iconClock} onClick={showModalHistory} style={{ cursor: 'pointer' }} alt="clock"/>
            </div>
            <Modal
              visible={isModalOpen}
              onCancel={handleCancel}
              footer={null}
              title={"Lịch sử cập nhật hội thoại"}
              className="custom-modal"
            >

              <div className="tab-headers">
                <div
                  className={`tab-header ${activeTab === 'history' ? 'active' : ''}`}
                  onClick={() => setActiveTab('history')}
                >
                  {activeTab === 'history' ? <span style={{ color: '#1890ff' }}>Thẻ hội thoại</span> : "Thẻ hội thoại"}

                </div>
                {/* <div
                  className={`tab-header ${activeTab === 'assignment' ? 'active' : ''}`}
                  onClick={() => setActiveTab('assignment')}
                >
                  {activeTab === 'assignment' ? <span style={{ color: '#1890ff' }}>Phân công nhân viên</span> : "Phân công nhân viên"}

                </div> */}
              </div>
              <div className={`tab-content ${activeTab === 'history' ? 'active' : ''}`}>
                <Timeline style={{ color: 'rgba(0, 0, 0, .65)', height: 'calc(60vh - 65px)', overflow: 'auto', padding: '10px 0px' }}>
                  {_.map(dataHistory, (items: any) => {
                    return (
                      <Timeline.Item color={items?.action === "DELETE" ? "red" : "blue"}>
                        <img src={items?.user?.avatar} style={{ marginRight: 5, border: '1px solid rgb(255, 255, 255)' }} className="rounded-circle avatar-xs" />
                        <span style={{ fontWeight: 'bold' }}>{items?.user?.name}</span>
                        <span style={{ marginLeft: 5, fontSize: 11, fontStyle: 'italic', color: '#a5a5a5' }}>{moment(items?.updatedAt).format("HH:mm DD/MM")}</span>
                        <div>
                          {items?.action === "DELETE" ? "Đã bỏ thẻ" : "Đã thêm thẻ"}&nbsp;
                          <span style={{ backgroundColor: items?.tag?.color, color: '#fff', textAlign: 'center', borderRadius: 4, display: 'inline-block', }}>&nbsp;{items?.tag?.name}&nbsp;</span>
                        </div>
                      </Timeline.Item>
                    )
                  })}
                </Timeline>
              </div>
              <div className={`tab-content ${activeTab === 'assignment' ? 'active' : ''}`}>
                {/* Content for "Phân công nhân viên" tab */}
                Phân công nhân viên content goes here...
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

interface UserHeadProps {
  onOpenUserDetails: () => void;
  className?: string;
}
const UserHead = ({
  onOpenUserDetails,
  className,
}: UserHeadProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();

  const state = createSelector(
    (props: any) => props.Chats,
    (props) => ({
      selectedConversation: props.selectedConversation,
      selectedChat: props.selectedChat,
      conversationList: props.conversationList,
      messagesHistories: props.messagesHistories,

    })
  );

  // Inside your component
  const { selectedConversation, selectedChat, conversationList, messagesHistories } = useAppSelector(state);


  const [translateReceivedTo, setTranslateReceiveTo] = useState(selectedConversation.extraInfo?.translation?.translateReceivedTo);
  const [translateSentTo, setTranslateSentTo] = useState(selectedConversation.extraInfo?.translation?.translateSentTo);
  const [isOpen, setIsOpen] = useState(false);
  const [listLanguage, setListLanguage] = useState<any>([]);

  const [fetching, setFetching] = useState(false);
  const [search, setSearch] = useState("");


  const localSearchFunc = (input: string, option: any) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const _handleSearch = useCallback((input: string) => {
    setFetching(true)
    setTimeout(() => {
      setSearch(input || "");
    }, 666000)
    setFetching(false)
  }, []);


  const onCloseConversation = () => {
    dispatch(changeSelectedChat(null));
  };

  const onSaveSettingLanguage = async () => {
    if (!translateReceivedTo || !translateSentTo) {
      return notification.warning({ message: 'Vui lòng chọn ngôn ngữ dịch' })
    }
    const payload = {
      autoTranslateReceived: true,
      showReceivedMessage: true,
      translateReceivedTo: translateReceivedTo,
      autoTranslateSent: true,
      showSentMessage: true,
      translateSentTo: translateSentTo,
      conversationId: selectedChat,
    }
    setIsOpen(false);
    const res: any = await updateTranslationsConversation(payload);
    if (res.success) {
      const resDetailConversation: any = await getDetailConversation({ conversationId: selectedChat });
      dispatch(changeSelectedConversation({
        ...selectedConversation,
        extraInfo: resDetailConversation?.data?.extraInfo,
      }));
      notification.success({ message: 'Lưu cấu hình thành công' });
    } else {
      notification.error({ message: 'Lưu cấu hình dịch thất bại' });
    }

  }

  const onUnReadConversation = async () => {
    if (selectedConversation?.readed) {
      const resReadConverSation: any = await readConversationId({ conversationId: selectedChat, readed: false });
      if (resReadConverSation?.success) {
        toast.success("Đánh dấu chưa đọc thành công")
        // Xử lý trường hợp read conversation
        const newConversationList = conversationList?.items?.map((conversation: any) => {
          if (conversation?.conversationId === selectedChat) {
            return { ...conversation, readed: false, unreadCount: resReadConverSation?.data?.unreadCount };
          } else return conversation
        });

        dispatch(chatsApiResponseSuccess(
          ChatsActionTypes.GET_CONVERSATION_LIST,
          {
            total: conversationList.total,
            page: conversationList.page,
            items: [...newConversationList],
          },
        ))
      }
    }
  }

  const getListLanguage = async () => {
    const res: any = await getTranslationsLanguages()
    if (res?.success) {
      setListLanguage(res.data)
    }
  }

  const onBan = async () => {
    const payload = {
      action: "BAN",
      channelId: selectedConversation?.channelId,
      conversationId: selectedChat,
      customerId: selectedConversation?.from?.customerId,
    }
    const res: any = await onBanCustomer(payload);
    if (res?.success) {
      toast.success("Chặn khách hàng thành công")
      dispatch(changeSelectedConversation({
        ...selectedConversation,
        ban: !selectedConversation?.ban,
      }));
    } else {
      toast.error(res?.data?.message?.error || res?.data?.message || 'Chặn khách hàng thất bại')
    }
  }
  const onSpam = async () => {
    const payload = {
      action: "SPAM",
      channelId: selectedConversation?.channelId,
      conversationId: selectedChat,
      customerId: selectedConversation?.from?.customerId,
    }
    const res: any = await onBanCustomer(payload);
    if (res?.success) {
      toast.success(!selectedConversation?.spam ? "Chuyển đến mục spam thành công" : "Chuyển đến mục thư mục chính thành công")
      dispatch(changeSelectedConversation({
        ...selectedConversation,
        spam: !selectedConversation?.spam,
      }));
      dispatch(refreshConversation(Math.random()));

    } else {
      toast.error("Chuyển đến mục spam thất bại")
    }
  }
  const checkMessageFirstUser = async (message: string) => {
    const resDetect: any = await onTranslationsDetect({ message: message });
    if (resDetect?.success) {
      const translateSentTo = resDetect?.data?.language;
      const translateReceivedTo = 'vi';
      const payload = {
        autoTranslateReceived: true,
        showReceivedMessage: true,
        translateReceivedTo: translateReceivedTo,
        autoTranslateSent: true,
        showSentMessage: true,
        translateSentTo: translateSentTo,
        conversationId: selectedConversation?.conversationId,
      }
      setIsOpen(false);
      const res: any = await updateTranslationsConversation(payload);
      if (res?.success) {
        const resDetailConversation: any = await getDetailConversation({ conversationId: selectedConversation?.conversationId });
        dispatch(changeSelectedConversation({
          ...selectedConversation,
          extraInfo: resDetailConversation?.data?.extraInfo,
        }));
      }
      setTranslateReceiveTo(translateReceivedTo);
      setTranslateSentTo(translateSentTo);
    }

  }


  useEffect(() => {
    if (!selectedConversation.extraInfo?.translation?.translateReceivedTo || !selectedConversation.extraInfo?.translation?.translateReceivedTo) {
      const messFirstUser = messagesHistories?.items?.find((item: any) => item?.from?.id !== item?.channelId);
      if (messFirstUser && messFirstUser?.text) {
        checkMessageFirstUser(messFirstUser?.text)
      }
    } else {
      setTranslateReceiveTo(selectedConversation.extraInfo?.translation?.translateReceivedTo);
      setTranslateSentTo(selectedConversation.extraInfo?.translation?.translateSentTo);
    }
  }, [selectedConversation.extraInfo?.translation?.translateReceivedTo, selectedConversation?.conversationId,
  selectedConversation.extraInfo?.translation?.translateSentTo, messagesHistories?.items]);

  const makeBusinessLink = async () => {
    const resConversation = await getDetailConversationFb({ conversationId: selectedConversation.conversationId });
    if (resConversation?.data?.updated_time) {
      selectedConversation.lastMessageAt = resConversation?.data?.updated_time;
    }
      window.postMessage({
        fb: {
          taskId: +new Date(),
          type: 'MAKE_BUSINESS_LINK',
          pageId: selectedConversation.pageId,
          threadId: selectedConversation.threadKey.split('_')[1],
          time: +new Date(selectedConversation.lastMessageAt),
        }
      }, '*');
    
  }
  useEffect(() => {
    getListLanguage();
  }, [])

  const openDropdown = () => {

    return (
      <div style={{ background: '#fff', height: 180, width: 500, padding: 20, borderRadius: 10 }}>
        <div className="d-flex justify-content-between pt-2">
          <span>
            <img style={{ width: 16, marginRight: 4 }} src={iconReceive} alt="iconTranslate" className="iconTranslate" />
            Cấu hình dịch gửi đến:
          </span>
          <Select placeholder="Chọn ngôn ngữ"
            onChange={(e) => { setTranslateReceiveTo(e) }}
            value={translateReceivedTo}
            style={{ width: 150 }}
            showSearch
            filterOption={localSearchFunc}
            notFoundContent={fetching ? <Spin size="small" /> : "Không có dữ liệu"}
            onSearch={_handleSearch}
          >
            {
              listLanguage?.map((item: any, index: number) => (
                <Option key={item.code} value={item.code} >{item.name}</Option>
              ))
            }
          </Select>
        </div>

        <div className="d-flex justify-content-between pt-4">
          <span>
            <img style={{ width: 16, marginRight: 4 }} src={iconSend} alt="iconTranslate" className="iconTranslate" />
            Cấu hình dịch gửi đi:
          </span>
          <Select placeholder="Chọn ngôn ngữ"
            showSearch
            filterOption={localSearchFunc}
            notFoundContent={fetching ? <Spin size="small" /> : "Không có dữ liệu"}
            onSearch={_handleSearch}
            onChange={(e) => { setTranslateSentTo(e) }}
            value={translateSentTo}
            style={{ width: 150 }}
          >
            {
              listLanguage?.map((item: any, index: number) => (
                <Option key={item.code} value={item.code} >{item.name}</Option>
              ))
            }
          </Select>
        </div>

        <div className="d-flex justify-content-center mb-2 mt-2">
          <Button type="primary" onClick={onSaveSettingLanguage}>Lưu cấu hình</Button>
        </div>

      </div>
    );
  };

  return (
    <div className={className}>
      <div className="p-3 p-lg-4 user-chat-topbar">
        <div className="d-flex justify-content-between">
          <ProfileImage
            selectedConversation={selectedConversation}
            onCloseConversation={onCloseConversation}
            onOpenUserDetails={onOpenUserDetails}
          />
          <div className="d-flex align-items-center">
            <Tooltip placement="top" title={selectedConversation?.spam ? "Chuyển đến mục thư mục chính" : "Chuyển đến mục spam"} >
              <div style={{ cursor: 'pointer', marginRight: 25 }} onClick={onSpam}>
                {
                  selectedConversation?.spam ? <MailOutlined /> : <InfoCircleOutlined />
                }
              </div>
            </Tooltip>
            <Tooltip placement="topLeft" title={"Đánh dấu chưa đọc"} >
              <div style={{ cursor: 'pointer' }} onClick={onUnReadConversation}>
                <img style={{ width: 21, height: 21, marginRight: 24 }} src={iconEmail} alt="iconTranslate" className="iconTranslate" />
              </div>
            </Tooltip>
            <Tooltip
              placement="top"
              title={
                selectedConversation?.ban ?
                  "Bỏ chặn khách hàng này" :
                  selectedConversation?.from?.id === selectedConversation?.channelId ?
                    "Không thể tự chặn chính mình" :
                    "Chặn khách hàng này"
              }
            >
              <div style={{ cursor: 'pointer', marginRight: 25 }} onClick={() => {
                if (selectedConversation?.from?.id !== selectedConversation?.channelId) {
                  onBan();
                }
              }}>
                {
                  selectedConversation?.ban ?
                    <MinusCircleOutlined style={{ background: 'red', borderRadius: 10, color: '#fff' }} /> :
                    <MinusCircleOutlined style={{ opacity: selectedConversation?.from?.id === selectedConversation?.channelId ? 0.5 : 1 }} />
                }
              </div>
            </Tooltip>
            <Tooltip placement="top" title={"Xem trên Facebook"} >
              <div style={{ cursor: 'pointer', marginRight: 25 }} onClick={makeBusinessLink}>
                {<ExportOutlined />}
              </div>
            </Tooltip>

            <ul className="list-inline user-chat-nav text-end mb-0">
              <div onClick={() => {
              }} className="list-inline-item d-none d-lg-inline-block">
                <Dropdown
                  open={isOpen}
                  onOpenChange={(val) => { setIsOpen(val) }}
                  trigger={['click']}
                  dropdownRender={() => openDropdown()}
                  placement="topRight"
                  arrow={{
                    pointAtCenter: true,
                  }}
                >
                  <i className="bx bx-dots-horizontal-rounded h4 mb-0"></i>
                </Dropdown>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};


export default styled(UserHead)`
 .ant-select-selection-item {
  text-align: left;
 }
  .send_by {
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #6B7588;
  } 
.custom-modal .ant-modal-content {
  padding: 16px; /* Adjust padding as needed */
}

.custom-modal .ant-modal-title {
  font-size: 16px; /* Adjust title font size as needed */
}

.custom-modal .ant-modal-body {
  display: flex;
  flex-direction: column;
}

.custom-modal .history-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px; /* Adjust margin as needed */
}

.custom-modal .history-item .icon {
  width: 20px; /* Adjust icon size as needed */
  height: 20px;
  border-radius: 50%;
  margin-right: 8px; /* Adjust margin as needed */
}

.custom-modal .history-item .name {
  font-weight: bold;
  margin-right: 8px; /* Adjust margin as needed */
}

.custom-modal .history-item .time {
  color: gray;
  margin-right: 8px; /* Adjust margin as needed */
}

.custom-modal .history-item .action {
  margin-right: 8px; /* Adjust margin as needed */
}

.custom-modal .history-item .label {
  display: inline-block;
  padding: 4px 8px; 
  border-radius: 4px;
  font-size: 12px; 
  color: white;
}
.tags-update-timeline {
    padding: 16px 24px;
    overflow: auto;
    height: calc(60vh - 65px);
}

.custom-modal .history-item .label.orange { background-color: orange; }
.custom-modal .history-item .label.purple { background-color: purple; }
.custom-modal .history-item .label.blue { background-color: blue; }
.custom-modal .history-item .label.green { background-color: green; }
`