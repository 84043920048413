import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const getFavourites = () => {
  return api.get(url.GET_FAVOURITES);
};

const getDirectMessages = () => {
  return api.get(url.GET_DIRECT_MESSAGES);
};
const getChannels = () => {
  return api.get(url.GET_CHANNELS);
};

const addContacts = (contacts: Array<string | number>) => {
  return api.create(url.ADD_CONTACTS, contacts);
};

const createChannel = (data: object) => {
  return api.create(url.CREATE_CHANNEL, data);
};

const getListGroup = (params: any) => {
  return api.get(url.CHANNELS + url.GROUP, { params: params });
};

const updateChannelGroup = (data: object) => {
  return api.create(url.CHANNELS + url.GROUP + url.UPDATE_MULTIPLE, data);
};

const getChatUserDetails = (id: string | number) => {
  return api.get(url.GET_CHAT_USER_DETAILS + "/" + id, { params: { id } });
};

const getChatUserConversations = (id: string | number) => {
  return api.get(url.GET_CHAT_USER_CONVERSATIONS + "/" + id, {
    params: { id },
  });
};


const receiveMessage = (id: string | number) => {
  return api.update(url.RECEIVE_MESSAGE + "/" + id, { params: { id } });
};

const readMessage = (id: string | number) => {
  return api.update(url.READ_MESSAGE + "/" + id, { params: { id } });
};

const receiveMessageFromUser = (id: string | number) => {
  return api.get(url.RECEIVE_MESSAGE_FROM_USER + "/" + id, {
    params: { id },
  });
};

const deleteMessage = (userId: number | string, messageId: number | string) => {
  return api.delete(url.DELETE_MESSAGE + "/" + userId + "/" + messageId, {
    params: { userId, messageId },
  });
};

const forwardMessage = (data: object) => {
  return api.create(url.FORWARD_MESSAGE, data);
};

const deleteUserMessages = (userId: number | string) => {
  return api.delete(url.DELETE_USER_MESSAGES + "/" + userId, {
    params: { userId },
  });
};

const getChannelDetails = (id: string | number) => {
  return api.get(url.GET_CHANNEL_DETAILS + "/" + id, { params: { id } });
};

const toggleFavouriteContact = (id: string | number) => {
  return api.update(url.TOGGLE_FAVOURITE_CONTACT + "/" + id, {
    params: { id },
  });
};

const getArchiveContact = () => {
  return api.get(url.GET_ARCHIVE_CONTACT);
};

const toggleArchiveContact = (id: string | number) => {
  return api.update(url.TOGGLE_ARCHIVE_CONTACT + "/" + id, { params: { id } });
};

const readConversation = (id: string | number) => {
  return api.update(url.READ_CONVERSATION + "/" + id, { params: { id } });
};

const deleteImage = (
  userId: number | string,
  messageId: number | string,
  imageId: number | string
) => {
  return api.delete(url.DELETE_IMAGE + "/" + userId + "/" + messageId, {
    params: { userId, messageId, imageId },
  });
};

const getMessageList = (id: string, params: any) => {
  return api.get(url.MESSAGES + "/" + id + url.HISTORIES, { params: params });
};

const sendMessage =  (id: string, params: any) => {
  return api.create(url.MESSAGES + "/" + id, params);
};

const onDeleteMessage =  (conversationId: string, messageId: string) => {
  return api.delete(url.MESSAGES + "/" + conversationId + "/" + messageId);
};

const onTranslationsDetect =  ( data: any) => {
  return api.create(url.TRANSLATIONS_DETECT, data);
};


const onTranslateText =  ( data: any) => {
  return api.create(url.TRANSLATIONS_TRANSLATE, data);
};

const onUpdateTranslationMessageId =  ( data: any) => {
  return api.update(`${url.UPDATE_TRANSLATIONS_MESSID}/${data.messageId}`, data);
};

const getMedias = (params: any) => {
  let obj: any = {
      channelId: params.channelId,
      page: params.page,
      limit: params.limit,
      q: params.q
  };
  if (params.folderIdSelected === '1') {
      obj.frequentlyUsed = true;
  } else if (params.folderIdSelected !== '0') {
    obj.folderId = params.folderIdSelected;
  }
  return api.get(url.CONTENT, { params: obj });
};

const getFolders = (params: any) => {
  return api.get(url.CONTENT + url.FOLDER, { params: params });
};

const createFolder = ( data: any)  => {
  return api.create(url.CONTENT + url.FOLDER, data);
};

const updateFolder = (id: string, data: any) => {
  return api.create(url.CONTENT + url.FOLDER, data);
};

const deleteFolder = (id: string) => {
  return api.delete(url.CONTENT + url.FOLDER + "/" + id);
};

const uploadMedia = (formData: any) => {
  return api.createWithFile(url.CONTENT, formData);
};

const updateMedia = (contentId: string, data: any) => {
  return api.update(url.CONTENT + "/" + contentId, data);
};

const deleteMedia = (contentId: string) => {
  return api.delete(url.CONTENT + "/" + contentId);
};

const updateReaction =  ( data: any) => {
  return api.create(`${url.SEND_MESSAGE}/${data.conversationId}/reaction`, data);
};

export {
  getFavourites,
  getDirectMessages,
  getChannels,
  addContacts,
  createChannel,
  getChatUserDetails,
  getChatUserConversations,
  sendMessage,
  receiveMessage,
  readMessage,
  receiveMessageFromUser,
  deleteMessage,
  forwardMessage,
  deleteUserMessages,
  getChannelDetails,
  toggleFavouriteContact,
  getArchiveContact,
  toggleArchiveContact,
  readConversation,
  deleteImage,
  getMessageList,
  getListGroup,
  updateChannelGroup,
  onTranslationsDetect,
  onTranslateText,
  onUpdateTranslationMessageId,
  getMedias,
  uploadMedia,
  updateMedia,
  deleteMedia,
  updateReaction,
  onDeleteMessage,
  getFolders,
  createFolder,
  updateFolder,
  deleteFolder,
};
