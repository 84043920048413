import React, { useEffect, useState } from "react";
import type { FormInstance, UploadProps } from 'antd';
import {
    Button,
    Form,
    Input,
    Space,
    Upload,
    Modal,
    Row,
    Col,
    Tooltip,
    Dropdown,
    message
} from 'antd';
import {
    addQuickReplies,
    updateQuickReplies,
    uploadMedia,
} from "../../../api/index";
import {
    PictureOutlined,
    CloseOutlined,
    PaperClipOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import InsertInfo from "./InsertInfo";

import { toast } from "react-toastify";

interface SubmitButtonProps {
    form: FormInstance;
}

const SubmitButton: React.FC<React.PropsWithChildren<SubmitButtonProps>> = ({ form, children }) => {
    const [submittable, setSubmittable] = React.useState<boolean>(false);

    // Watch all values
    const values = Form.useWatch([], form);

    React.useEffect(() => {
        form
            .validateFields({ validateOnly: true })
            .then(() => setSubmittable(true))
            .catch(() => setSubmittable(false));
    }, [form, values]);

    return (
        <Button type="primary" htmlType="submit" disabled={!submittable}>
            {children}
        </Button>
    );
};

const { TextArea } = Input;

interface AddQuickRepliesModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSuccess: () => void;
    channelSelected: string;
    messageSelected: any;
}

const AddQuickReply = ({ isOpen, onClose, onSuccess, channelSelected, messageSelected }: AddQuickRepliesModalProps) => {
    const [listImage, setListImage] = useState<any[]>([]);
    const [listFile, setListFile] = useState<any[]>([]);
    const [keyFocus, setKeyFocus] = React.useState<string>('0');
    const [form] = Form.useForm();
    const validateMessages = {
        required: '${label} bắt buộc!',
    };

    useEffect(() => {
        if (messageSelected) {
            form.setFieldsValue({
                shortcut: messageSelected?.shortcut || '',
                items: messageSelected?.messages?.length > 0 ? messageSelected?.messages?.map((message: any, key: any) => {
                    return {
                        content: message?.message || '',
                        images: message?.photos?.length > 0 ? message?.photos : [],
                        files: message?.files?.length > 0 ? message?.files : [],
                    };
                }) : [{
                    content: '',
                    images: [],
                    files: []
                }],
            });
            setListImage(
                messageSelected?.messages?.length > 0 ? messageSelected?.messages.map((message: any, key: any) => {
                    return {
                        name: key,
                        images: message?.photos?.length > 0 ? message?.photos : [],
                    };
                }) : []
            );
            setListFile(
                messageSelected?.messages?.length > 0 ? messageSelected?.messages.map((message: any, key: any) => {
                    return {
                        name: key,
                        files: message?.files?.length > 0 ? message?.files : [],
                    };
                }) : []
            );
        } else {
            form.setFieldsValue({
                items: [{
                    content: '',
                    images: [],
                    files: []
                }]
            });
        }
    }, [messageSelected]);

    const onFinish = async (values: any) => {
        try {
            const response: any = await addQuickReplies({
                "channelId": channelSelected,
                "shortcut": values.shortcut,
                "messages": values.items.map((item: any, key: any) => {
                    return {
                        "message": item.content,
                        "photos": listImage.find((img: any) => img?.name == key)?.images.map((img: any) => img?.id) || [],
                        "files": listFile.find((file: any) => file?.name == key)?.files.map((file: any) => file?.id) || [],
                    };
                }),
            });
            if (response?.data && response?.success) {
                onSuccess();
            } else {
                toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const onSave = async (values: any) => {
        try {
            const response: any = await updateQuickReplies(messageSelected?.id, {
                "channelId": channelSelected,
                "shortcut": values.shortcut,
                "messages": values.items.map((item: any, key: any) => {
                    return {
                        "message": item.content,
                        "photos": listImage.find((img: any) => img?.name == key)?.images.map((img: any) => img?.id) || [],
                        "files": listFile.find((file: any) => file?.name == key)?.files.map((file: any) => file?.id) || [],
                    };
                }),
            });
            if (response?.data && response?.success) {
                onSuccess();
            } else {
                toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    return (
        <Modal
            open={isOpen}
            footer={null}
            onCancel={onClose}
            width={1100}
        >
            <div className="p-0">
                <div className="pb-0">
                    <div style={{
                        borderBottom: '1px solid #d0d0d0',
                        marginLeft: -24,
                        marginRight: -24,
                        padding: '0 24px 16px 24px',
                    }}>
                        <span style={{ fontWeight: 'bold', fontSize: 16 }}>
                            {
                                messageSelected ? 'Sửa câu trả lời nhanh ' + messageSelected?.indexQuickReplies : 'Thêm câu trả lời nhanh'
                            }
                        </span>
                    </div>
                    <Form
                        form={form}
                        name="add-quickReplies"
                        layout="vertical"
                        autoComplete="off"
                        onFinish={messageSelected ? onSave : onFinish}
                        validateMessages={validateMessages}
                        initialValues={{
                            items: [{
                                content: '',
                                images: [],
                                files: []
                            }]
                        }}
                    >
                        <Row>
                            <Col span={16} style={{
                                padding: '24px 24px 0 0',
                                height: 'calc(100vh - 150px)',
                                overflow: 'auto',
                            }}>
                                <div style={{
                                    fontWeight: 'bold',
                                    marginBottom: 16,
                                }}>
                                    Chọn chủ đề
                                </div>
                                <Tooltip title="Ký tự tắt không đặt dạng số, không trùng lặp và chỉ chấp nhận các kí tự đặc biệt $-_.+!*'()">
                                    <Form.Item
                                        name="shortcut"
                                        label="Ký tự tắt"
                                        rules={[{
                                            pattern: /^[a-zàáâãèéêìíòóôõùúăđĩũơưăạảấầẩẫậắằẳẵặẹẻẽềềểếễệỉịọỏốồổỗộớờởỡợụủứừửữựỳỵỷỹý$+!*'()._-]+$/i,
                                            message: 'Ký tự tắt không đặt dạng số, không trùng lặp và chỉ chấp nhận các kí tự đặc biệt $-_.+!*\'()'
                                         }]}
                                    >
                                        <Input
                                            placeholder="Nhập nội dung kí tự"
                                            style={{ width: '100%', position: 'relative', paddingLeft: 32 }}
                                            prefix={
                                                <div style={{
                                                    position: 'absolute',
                                                    left: 0,
                                                    top: 0,
                                                    borderTopLeftRadius: 6,
                                                    borderBottomLeftRadius: 6,
                                                    width: 28,
                                                    height: '100%',
                                                    backgroundColor: '#E4E7EC',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}>/</div>
                                            }
                                        />
                                    </Form.Item>
                                </Tooltip>
                                <div style={{
                                    fontWeight: 'bold',
                                    marginBottom: 16,
                                }}>
                                    Nội dung
                                </div>
                                <Form.List name="items">
                                    {(fields, { add, remove }) => (
                                        <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
                                            {fields.map((field, index) => (
                                                <div
                                                    key={index}
                                                    style={{
                                                        position: 'relative',
                                                        padding: '20px 30px',
                                                        borderRadius: 6,
                                                        backgroundColor: '#E4E7EC',
                                                    }}>
                                                    <Form.Item label={`Nội dung ${field.name + 1}`} name={[field.name, 'content']} rules={[{ required: true }]}>
                                                        <TextArea
                                                            rows={4}
                                                            onFocus={
                                                                () => setKeyFocus(field.name.toString())
                                                            }
                                                        />
                                                    </Form.Item>
                                                    <Form.Item label={`Hình ảnh ${field.name + 1}`} name={[field.name, 'images']}>
                                                        <Upload
                                                            listType="picture-card"
                                                            multiple
                                                            fileList={
                                                                listImage.find((item: any) => item?.name == field.name)?.images.map((item: any, index: number) => {
                                                                    return {
                                                                        uid: item?.id,
                                                                        name: item?.originalname,
                                                                        url: item?.location,
                                                                    };
                                                                }) || []
                                                            }
                                                            customRequest={async ({ file }) => {
                                                                const formData = new FormData();
                                                                formData.append('files', file);
                                                                const response: any = await uploadMedia(formData);
                                                                if (response?.data && response?.success) {
                                                                    const img = listImage.find((item: any) => item?.name == field.name);
                                                                    setListImage(
                                                                        listImage.filter((item: any) => item?.name !== field.name).concat({
                                                                            name: field.name,
                                                                            images: img ? img?.images.concat(response?.data?.[0]) : [response?.data?.[0]],
                                                                        })
                                                                    );
                                                                } else {
                                                                    toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
                                                                }
                                                            }}
                                                            onRemove={(info) => {
                                                                setListImage(
                                                                    listImage.map((item: any) => {
                                                                        if (item?.name == field.name) {
                                                                            return {
                                                                                name: item?.name,
                                                                                images: item?.images.filter((image: any) => image?.id !== info?.uid),
                                                                            };
                                                                        }
                                                                        return item;
                                                                    })
                                                                );
                                                            }}
                                                        >
                                                            <button style={{ border: 0, background: 'none' }} type="button">
                                                                <PictureOutlined style={{ fontSize: 20, color: '#2D65C3' }} />
                                                                <div style={{ fontSize: 13, fontWeight: '500', color: '#2D65C3' }}>Tải ảnh</div>
                                                            </button>
                                                        </Upload>
                                                    </Form.Item>
                                                    <Form.Item label={`File ${field.name + 1}`} name={[field.name, 'files']}>
                                                        <Dropdown
                                                            trigger={['click']}
                                                            // placement="topRight"
                                                            getPopupContainer={(trigger: any) => trigger.parentNode}
                                                            dropdownRender={() => (
                                                                <div style={{
                                                                    width: 400,
                                                                    height: 400,
                                                                    padding: 20,
                                                                    borderRadius: 10,
                                                                    border: '1px solid #d9d9d9',
                                                                    backgroundColor: 'white',
                                                                    overflow: 'auto',
                                                                }}>
                                                                    <Upload
                                                                        fileList={
                                                                            listFile.find((item: any) => item?.name == field.name)?.files.map((item: any, index: number) => {
                                                                                return {
                                                                                    uid: item?.id,
                                                                                    name: item?.originalname,
                                                                                    url: item?.location,
                                                                                };
                                                                            }) || []
                                                                        }
                                                                        beforeUpload={(file) => {
                                                                            const isFile = !file.type.includes('image');
                                                                            if (!isFile) {
                                                                                message.error(`${file.name} is not a video or a file`);
                                                                            }
                                                                            return isFile || Upload.LIST_IGNORE;
                                                                        }}
                                                                        customRequest={async ({ file }) => {
                                                                            const formData = new FormData();
                                                                            formData.append('files', file);
                                                                            const response: any = await uploadMedia(formData);
                                                                            if (response?.data && response?.success) {
                                                                                const img = listFile.find((item: any) => item?.name == field.name);
                                                                                setListFile(
                                                                                    listFile.filter((item: any) => item?.name !== field.name).concat({
                                                                                        name: field.name,
                                                                                        files: img ? img?.files.concat(response?.data?.[0]) : [response?.data?.[0]],
                                                                                    })
                                                                                );
                                                                            } else {
                                                                                toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
                                                                            }
                                                                        }}
                                                                        onRemove={(info) => {
                                                                            setListFile(
                                                                                listFile.map((item: any) => {
                                                                                    if (item?.name == field.name) {
                                                                                        return {
                                                                                            name: item?.name,
                                                                                            files: item?.files.filter((image: any) => image?.id !== info?.uid),
                                                                                        };
                                                                                    }
                                                                                    return item;
                                                                                })
                                                                            );
                                                                        }}
                                                                    >
                                                                        <Button icon={<UploadOutlined />}>Tải file lên</Button>
                                                                    </Upload>
                                                                </div>
                                                            )}
                                                            arrow={{
                                                                pointAtCenter: true,
                                                            }}
                                                        >
                                                            <div style={{
                                                                width: 100,
                                                                height: 100,
                                                                borderRadius: 8,
                                                                border: '1px dashed #d9d9d9',
                                                                backgroundColor: '#00000005',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                cursor: 'pointer',
                                                                position: 'relative',
                                                            }}>
                                                                <PaperClipOutlined
                                                                    style={{ fontSize: 23, color: '#2D65C3' }}
                                                                />
                                                                {
                                                                    listFile.find((item: any) => item?.name == field.name)?.files?.length > 0 ? (
                                                                        <div className="badge" style={{
                                                                            fontSize: listFile.find((item: any) => item?.name == field.name)?.files?.length > 99 ? 7 : 10,
                                                                            position: 'absolute',
                                                                            top: -9,
                                                                            right: -9,
                                                                            width: 16,
                                                                            height: 16,
                                                                            backgroundColor: 'red',
                                                                            borderRadius: '50%',
                                                                            color: 'white',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            fontWeight: 'bold',
                                                                            border: '1px solid white',
                                                                        }}>
                                                                            {
                                                                                listFile.find((item: any) => item?.name == field.name)?.files?.length > 99 ?
                                                                                    "99+" :
                                                                                    listFile.find((item: any) => item?.name == field.name)?.files?.length
                                                                            }
                                                                        </div>
                                                                    ) : null
                                                                }
                                                            </div>
                                                        </Dropdown>
                                                    </Form.Item>
                                                    {
                                                        field.name !== 0 ? (
                                                            <CloseOutlined
                                                                style={{
                                                                    position: 'absolute',
                                                                    right: 30,
                                                                    top: 20,
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => {
                                                                    remove(field.name);
                                                                }}
                                                            />
                                                        ) : null
                                                    }
                                                </div>
                                            ))}

                                            <Button type="dashed" onClick={() => {
                                                add();
                                                setKeyFocus((fields.length).toString());
                                            }} block style={{
                                                display: 'inline-block',
                                                width: 'fit-content',
                                                marginBottom: 24,
                                            }}>
                                                <span style={{ fontWeight: '500', color: '#2D65C3' }}>+ Thêm tin nhắn</span>
                                            </Button>
                                        </div>
                                    )}
                                </Form.List>
                            </Col>
                            <Col span={8} style={{
                                borderLeft: '1px solid #d0d0d0',
                                padding: '24px 0 24px 24px',
                                height: 'calc(100vh - 150px)',
                                overflow: 'auto',
                            }}>
                                <InsertInfo
                                    onInsert={(content: string) => {
                                        form.setFieldsValue({
                                            items: form.getFieldValue('items').map((item: any, key: any) => {
                                                if (key == keyFocus) {
                                                    return {
                                                        ...item,
                                                        content: item?.content ? item?.content + ' ' + content : content,
                                                    };
                                                }
                                                return item;
                                            }),
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                        <Form.Item style={{
                            textAlign: 'right',
                            padding: '24px 24px 0 24px',
                            borderTop: '1px solid #d0d0d0',
                            marginLeft: -24,
                            marginRight: -24,
                            marginBottom: 0
                        }}>
                            <Space>
                                <Button
                                    onClick={onClose}
                                >Đóng</Button>
                                <SubmitButton form={form}>
                                    Lưu
                                </SubmitButton>
                            </Space>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Modal>
    );
};

export default AddQuickReply;
