import React, { useState, useRef, useEffect } from "react";
import { Tabs, Modal } from 'antd';

// router
import { useNavigate } from "react-router-dom";

//api
import {
    getListGroup,
    updateChannelGroup
} from "../../../api/index";

// component
import ChannelList from './ChannelList';
import GroupName from './GroupName';
import { toast } from "react-toastify";

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

interface ModalGroupProps {
    isOpen: boolean;
    onClose: () => void;
}

const Index = ({ isOpen, onClose }: ModalGroupProps) => {
    const navigate = useNavigate();
    const [groups, setGroups] = useState<any[]>([]);
    const [activeKey, setActiveKey] = useState<string>('');
    const [items, setItems] = useState<any[]>([]);

    useEffect(() => {
        if (groups.length === 0) {
            getListGroups();
        }
    }, [groups]);

    const getListGroups = async () => {
        try {
            const response: any = await getListGroup({
                page: 1,
                limit: 50
            });
            if (response?.data?.items?.length > 0) {
                const list = response?.data?.items?.find((i: any) => i?.name === 'Mặc định') ? [response?.data?.items?.find((i: any) => i?.name === 'Mặc định')].concat(response?.data?.items?.filter((i: any) => i?.name !== 'Mặc định')) : response?.data?.items;
                const groups = list?.map((item: any, index: number) => ({
                    id: item?.id,
                    name: item.name,
                    key: index.toString(),
                    channelIds: item.channelIds
                }));
                setItems(groups.map((item: any, index: number) => ({
                    label:
                        <GroupName
                            groupKey={index.toString()}
                            name={item.name}
                            disabled={item.name === 'Mặc định'}
                            onSetName={onSetName}
                            groups={groups}
                        />,
                    children:
                        <ChannelList
                            groupKey={index.toString()}
                            groups={groups}
                            onSetGroups={onSetGroups}
                        />,
                    key: index.toString(),
                    closable: item.name === 'Mặc định' ? false : true,
                })));
                setActiveKey('0');
                setGroups(groups);
            } else {
                const response: any = await updateChannelGroup(
                    {
                        groups: [{
                            name: 'Mặc định',
                            channelIds: []
                        }]
                    }
                );
                if (response?.data && response?.success) {
                    const initialItems = [
                        {
                            label:
                                <GroupName
                                    name={'Mặc định'}
                                    disabled
                                />, 
                            children:
                                <ChannelList
                                    groupKey={'0'}
                                    groups={[{
                                        name: 'Mặc định',
                                        key: '0',
                                        channelIds: [],
                                        id: response?.data[0]?.id
                                    }]}
                                    onSetGroups={onSetGroups}
                                />,
                            key: '0',
                            closable: false,
                        },
                    ];
                    setItems(initialItems);
                    setActiveKey('0');
                    setGroups([{
                        id: response?.data[0]?.id,
                        name: 'Mặc định',
                        key: '0',
                        channelIds: []
                    }]);
                }
            }
        } catch (error: any) {
            console.log("error", error);
        }
    };

    const onChange = (newActiveKey: string) => {
        setActiveKey(newActiveKey);
    };

    const onSetGroups = async (key: string, groups: any) => {
        try {
            const response: any = await updateChannelGroup(
                {
                    groups: groups.map((item: any) => ({
                        name: item.name,
                        channelIds: item.channelIds
                    }))
                }
            );
            if (response?.data && response?.success) {
                const list = response?.data?.find((i: any) => i?.name === 'Mặc định') ? [response?.data?.find((i: any) => i?.name === 'Mặc định')].concat(response?.data?.filter((i: any) => i?.name !== 'Mặc định')) : response?.data;
                const arr = list?.map((item: any, index: number) => ({
                    id: item?.id,
                    name: item.name,
                    key: index.toString(),
                    channelIds: item.channelIds
                }));
                setItems(arr.map((item: any) => ({
                    label:
                        <GroupName
                            groupKey={item.key}
                            disabled={item.name === 'Mặc định'}
                            name={item.name}
                            onSetName={onSetName}
                            groups={arr}
                        />,
                    children:
                        <ChannelList
                            groupKey={item.key}
                            groups={arr}
                            onSetGroups={onSetGroups}
                        />,
                    key: item.key,
                    closable: item.name === 'Mặc định' ? false : true,
                })));
                setGroups(arr);
            } else {
                toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const onSetName = async (groups: any) => {
        try {
            const response: any = await updateChannelGroup(
                {
                    groups: groups.map((item: any) => ({
                        name: item.name,
                        channelIds: item.channelIds
                    }))
                }
            );
            if (response?.data && response?.success) {
                const list = response?.data?.find((i: any) => i?.name === 'Mặc định') ? [response?.data?.find((i: any) => i?.name === 'Mặc định')].concat(response?.data?.filter((i: any) => i?.name !== 'Mặc định')) : response?.data;
                const arr = list?.map((item: any, index: number) => ({
                    id: item?.id,
                    name: item.name,
                    key: index.toString(),
                    channelIds: item.channelIds
                }));
                setItems(arr.map((item: any) => ({
                    label:
                        <GroupName
                            groupKey={item.key}
                            disabled={item.name === 'Mặc định'}
                            name={item.name}
                            onSetName={onSetName}
                            groups={arr}
                        />,
                    children:
                        <ChannelList
                            groupKey={item.key}
                            groups={arr}
                            onSetGroups={onSetGroups}
                        />,
                    key: item.key,
                    closable: item.name === 'Mặc định' ? false : true,
                })));
                setGroups(arr);
            } else {
                toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const add = async () => {
        const newActiveKey = `${groups.length}`;
        const newName = `${groups.length - 1}`;
        const name = groups.find((item) => item.name === ('Group ' + newName)) ? 'Group ' + newName + '(1)' : 'Group ' + newName;
        const arr = groups.concat({
            name: name,
            key: newActiveKey,
            channelIds: [
            ]
        });
        try {
            const response: any = await updateChannelGroup(
                {
                    groups: arr.map((item) => ({
                        name: item.name,
                        channelIds: item.channelIds
                    }))
                }
            );
            if (response?.data && response?.success) {
                const list = response?.data?.find((i: any) => i?.name === 'Mặc định') ? [response?.data?.find((i: any) => i?.name === 'Mặc định')].concat(response?.data?.filter((i: any) => i?.name !== 'Mặc định')) : response?.data;
                const groupAdd = list?.map((item: any, index: number) => ({
                    id: item?.id,
                    name: item.name,
                    key: index.toString(),
                    channelIds: item.channelIds
                }));
                setItems(groupAdd.map((item: any) => ({
                    label:
                        <GroupName
                            groupKey={item.key}
                            disabled={item.name === 'Mặc định'}
                            name={item.name}
                            onSetName={onSetName}
                            groups={groupAdd}
                        />,
                    children:
                        <ChannelList
                            groupKey={item.key}
                            groups={groupAdd}
                            onSetGroups={onSetGroups}
                        />,
                    key: item.key,
                    closable: item.name === 'Mặc định' ? false : true,
                })));
                setActiveKey(newActiveKey);
                setGroups(groupAdd);
            } else {
                toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const remove = async (targetKey: TargetKey) => {
        let newActiveKey = activeKey;
        let lastIndex = -1;
        items.forEach((item: any, i: any) => {
            if (item.key === targetKey) {
                lastIndex = i - 1;
            }
        });
        const newPanes = items.filter((item: any) => item.key !== targetKey);
        if (newPanes.length && newActiveKey === targetKey) {
            if (lastIndex >= 0) {
                newActiveKey = newPanes[lastIndex].key;
            } else {
                newActiveKey = newPanes[0].key;
            }
        }
        const arr = groups.filter((item) => item.key !== targetKey);
        try {
            const response: any = await updateChannelGroup(
                {
                    groups: arr.map((item) => ({
                        name: item.name,
                        channelIds: item.channelIds
                    }))
                }
            );
            if (response?.data && response?.success) {
                const list = response?.data?.find((i: any) => i?.name === 'Mặc định') ? [response?.data?.find((i: any) => i?.name === 'Mặc định')].concat(response?.data?.filter((i: any) => i?.name !== 'Mặc định')) : response?.data;
                const groupRemove = list?.map((item: any, index: number) => ({
                    id: item?.id,
                    name: item.name,
                    key: index.toString(),
                    channelIds: item.channelIds
                }));
                setItems(groupRemove.map((item: any) => ({
                    label:
                        <GroupName
                            groupKey={item.key}
                            disabled={item.name === 'Mặc định'}
                            name={item.name}
                            onSetName={onSetName}
                            groups={groupRemove}
                        />,
                    children:
                        <ChannelList
                            groupKey={item.key}
                            groups={groupRemove}
                            onSetGroups={onSetGroups}
                        />,
                    key: item.key,
                    closable: item.name === 'Mặc định' ? false : true,
                })));
                setActiveKey(newActiveKey);
                setGroups(groupRemove);
            } else {
                toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const onEdit = (
        targetKey: React.MouseEvent | React.KeyboardEvent | string,
        action: 'add' | 'remove',
    ) => {
        if (action === 'add') {
            add();
        } else {
            remove(targetKey);
        }
    };

    const onMergePages = () => {
        onClose();
        const group = groups.find((item) => item.key === activeKey);
        navigate(`/conversation-list?groupId=${group?.id}`);
    };

    return (
        <Modal
            open={isOpen}
            title="Chế độ gộp trang"
            onCancel={onClose}
            onOk={onMergePages}
            cancelText="Đóng"
            okText="Vào chế độ gộp trang"
            okButtonProps={{
                disabled: !(activeKey?.length > 0 && groups.find((item) => item.key === activeKey)?.channelIds.length > 1),
            }}
            width={1010}
        >
            <Tabs
                type="editable-card"
                onChange={onChange}
                activeKey={activeKey}
                onEdit={onEdit}
                items={items}
            />
        </Modal>
    );
};

export default Index;
