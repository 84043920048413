import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";

// hooks
import { useRedux } from "../../../hooks/index";
import { createSelector } from "reselect";

// actions
import {
  getMessagesHistories,
} from "../../../redux/actions";

// components
import Loader from "../../../components/Loader";
import Message from "./Message";

interface ConversationProps {
}
const MessagesHistories = ({
}: ConversationProps) => {
  const { dispatch, useAppSelector } = useRedux();
  const [scrollRef, setScrollRef] = useState<any>(null);
  const errorData = createSelector(
    (state: any) => state.Chats,
    (state) => ({
      getUserConversationsLoading: state.getUserConversationsLoading,
      isMessageForwarded: state.isMessageForwarded,
      selectedConversation: state.selectedConversation,
      messagesHistories: state.messagesHistories,
    })
  );
  
  const { getUserConversationsLoading, selectedConversation, messagesHistories } = useAppSelector(errorData);
  const handleScrollToTop = useCallback(() => { // check scroll đến top thì load thêm message
    const element = document.getElementById("chat-conversation-list");
    if (element) {
      const { scrollTop, clientHeight, scrollHeight } = element;
      if ((-scrollTop + clientHeight + 1 >= scrollHeight) && messagesHistories?.total > messagesHistories?.items?.length) {
        setScrollRef(scrollTop);
        dispatch(getMessagesHistories({
          id: selectedConversation?.conversationId,
          page: messagesHistories?.page + 1,
          limit: 20,
        }));
      }
    }
  }, [dispatch, selectedConversation, messagesHistories]);

  useEffect(() => {
    const element = document.getElementById("chat-conversation-list");
    if (element) {
      element.addEventListener("scroll", handleScrollToTop);
    }
    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScrollToTop);
      }
    };
  }, [handleScrollToTop]);

  const scrollElementTop = useCallback(() => {
    const element = document.getElementById("chat-conversation-list");
    element?.scrollTo({top: scrollRef});
  }, [scrollRef]);

  useEffect(() => { // check list message thay đổi thì scroll
    if (messagesHistories.items?.length > 0 && messagesHistories?.isGetMore && scrollRef) {
      scrollElementTop();
    }
  }, [messagesHistories]);
  const renderGroupedDateMessages = () => {
    const groupedMessages = messagesHistories?.items?.length > 0 && messagesHistories?.items?.reduce((groups: any, message: any) => {
      const date = moment(message.createdAt).format("DD/MM/YYYY");
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});
    const groupedMessagesArray = Object.entries(groupedMessages).map(([date, messages]) => ({
      date,
      messages
    }));
    return (
      groupedMessagesArray.map((group: any, index: number) => {
        return (
          <div key={index}>
            <div className="chat-day-title">
              <span className="title">
                {
                  group.date == moment().format('DD/MM/YYYY') ?
                    'Hôm nay' :
                      group.date == moment().subtract(1, 'days').format('DD/MM/YYYY') ?
                        'Hôm qua' :
                        group.date
                }
              </span>
            </div>
            {
              renderGroupedTimeMessages(group.messages)
            }
          </div>
        );
      })
    );
  };
  const renderGroupedTimeMessages = (messageList: any) => {
    const seenMessages = messagesHistories?.items?.filter((message: any) => message.seen);
    const lastSeenMessage = seenMessages?.[seenMessages?.length - 1] || null;

    const groupedMessages = messageList?.length > 0 && messageList.reduce((groups: any, message: any) => {
      const date = new Date(moment(message.createdAt).valueOf());
      const hour = date.getHours();
      if (!groups[hour]) {
        groups[hour] = [];
      }
      groups[hour].push(message);
      return groups;
    }, {});
    const groupedMessagesArray = Object.entries(groupedMessages).map(([hour, messages]) => ({
      hour,
      messages
    }));
    return (
      groupedMessagesArray.map((group: any, index: number) => {
        return (
          <div
            key={index}
            style={{
              marginBottom: index == groupedMessagesArray?.length - 1 ? 0 : 60,
            }}
          >
            <ul className="list-unstyled chat-conversation-list">
              {group.messages.map((message: any, key: number) => {
                const isFromMe = message?.from.id == message?.channelId;
                return (
                  <Message
                    message={message}
                    key={key}
                    selectedConversation={selectedConversation}
                    isFromMe={isFromMe}
                  />
                );
              })}
            </ul>
            {
              group.messages[group.messages?.length - 1]?.from?.id !== selectedConversation?.conversationId ? (
                group.messages[group.messages?.length - 1]?.createdBy?.name ? (
                  <div
                    style={{
                      justifyContent: 'flex-end',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <div style={{fontSize: 11, color: '#495057bf'}}>
                      Người gửi: <span style={{fontSize: 11, color: '#1A1A1A'}}>{group.messages[group.messages?.length - 1]?.createdBy?.name}</span>
                    </div>
                  </div>
                ) : null
              ) : (
                group.messages[group.messages?.length - 1]?.from?.name ? (
                  <div
                    style={{
                      justifyContent: 'flex-start',
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: 44,
                    }}>
                    <div style={{fontSize: 11, color: '#495057bf'}}>
                      Người gửi: <span style={{fontSize: 11, color: '#1A1A1A'}}>{group.messages[group.messages?.length - 1]?.from?.name}</span>
                    </div>
                  </div>
                ) : null
              )
            }
          </div>
        );
      })
    );
  };
  return (
    <div
      className="chat-conversation p-3 p-lg-4 positin-relative"
      id="chat-conversation-list"
      style={{
        overflowY: "auto",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column-reverse",
      }}
    >
      <div style={{ marginTop: 90,  paddingTop: 10, marginBottom: 0, paddingLeft: 0, position: 'relative' }}
      >
      {getUserConversationsLoading && 
        <Loader />}
        {messagesHistories?.items?.length > 0 && renderGroupedDateMessages()}
      </div>
    </div>
  );
};

export default MessagesHistories;
