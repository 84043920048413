import React, { useEffect, useState } from "react";

// scss
import "./assets/scss/theme.scss";

//Route
import Routes from "./routes";
import { getSettingsApp } from "./api/auth";



const App = () => {
  const [infoSetting, setInfoSetting] = useState({});

  const getLogoTitle = async () => {
    const res: any = await getSettingsApp();
    if(res?.success) {
      localStorage.setItem("setting", JSON.stringify(res?.data));
      document.title = res?.data?.title || '';
      let link: any = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = res?.data?.logoUrl || '';
      setInfoSetting(res?.data)
    }
  }
  useEffect(() => {
    getLogoTitle()
  }, []);

  return <Routes infoSetting={infoSetting} />;
};

export default App;
