import React, { useState } from "react";
import classnames from "classnames";
import moment from "moment";
import {
  Select,
  Dropdown,
  Badge
} from 'antd';
import {
  CommentOutlined,
} from '@ant-design/icons';
// styles
import styled from "styled-components";

// utils
import { formateDate } from "../../../utils";

interface ChatUserProps {
  user: any;
  selectedChat: string | number;
  onSelectConversation?: (obj: any) => void;
  className?: string;
}
const ChatUser = ({ user, selectedChat, onSelectConversation, className }: ChatUserProps) => {
  const fullName = user?.from?.name;
  const shortName = user?.from?.name?.charAt(0);
  const time = `${formateDate(user?.lastMessageAt, "HH:mm")} ${
    moment(user?.lastMessageAt).format('DD/MM/YYYY') == moment().format('DD/MM/YYYY') ?
      '' :
      moment(user?.lastMessageAt).format('DD/MM/YYYY') == moment().subtract(1, 'days').format('DD/MM/YYYY') ?
        'hôm qua' :
        moment(user?.lastMessageAt).format('DD/MM/YY')
  }`;

  const colors = [
    "#4eac6d",
    "bg-danger",
    "bg-info",
    "bg-warning",
    "bg-secondary",
    "bg-pink",
    "bg-purple",
  ];
  const [color] = useState(Math.floor(Math.random() * colors.length));
  const isOnline = true;
  const unRead = user?.readed;

  const isSelectedChat: boolean =
    selectedChat && selectedChat === user?.conversationId ? true : false;
  const onClick = () => {
    if (isSelectedChat) return;
    onSelectConversation && onSelectConversation(user);
  };

  return (
    <li className={classnames(className, { active: isSelectedChat })} onClick={onClick} style={unRead ? {} : {background: 'rgb(234, 234, 234)' }}>
      <a className={classnames({ "unread-msg-user": unRead })}>
        <div className="d-flex align-items-center">
          <Badge  count={user?.unreadCount} offset={[-16, 34]}>
            <div
              className={classnames( "chat-user-img", "align-self-center",  "me-3", "ms-0",  )}
            >
              {user?.from?.avatar ? (
                <>
                  <img
                    src={user?.from?.avatar}
                    className="rounded-circle avatar-sm"
                    alt=""
                  />
                </>
              ) : (
                <div className="avatar-sm">
                  <span
                    className={classnames( "avatar-title",  "rounded-circle", "text-uppercase", "text-white",  colors[color] )}
                  >
                    <span className="username">{shortName}</span>
                    <span className="user-status"></span>
                  </span>
                </div>
              )}
            </div>
          </Badge>
          <div className="overflow-hidden w-100">
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
              <p
                className="text-truncate mb-0"
                style={{
                  width: time?.length > 6 ? 'calc(100% - 90px)' : 'calc(100% - 50px)',
                }}
              >{fullName}</p>
              <p
                className="mb-0"
                style={{
                  width: time?.length > 6 ? 90 : 50,
                  fontSize: 12,
                  color: '#666666',
                  fontWeight: '400',
                  textAlign: 'right'
                }}
              >{time}</p>
            </div>
            <p className="snippet"  style={user?.readed ? {fontWeight: '400'} : {fontWeight: '600'}}>{user?.snippet}</p>
            <div className="d-flex justify-content-end align-items-center">
              {
                user?.tags?.length > 0 ? (
                  <Select
                    className="tag-select"
                    mode="multiple"
                    style={{ width: '100%' }}
                    maxTagCount={'responsive'}
                    value={user?.tags?.map((item: any) => item?.name)}
                    maxTagPlaceholder={(omittedValues) => (
                      <Dropdown
                        placement="topLeft"
                        menu={{
                          items: [{
                            label: (
                              <div>
                                {
                                  omittedValues.map(({ label }, key) => (
                                    <span key={key}
                                      style={{
                                        padding: "4px",
                                        backgroundColor: user?.tags?.find((item: any) => item?.name === label)?.color || "gray",
                                        borderRadius: "4px",
                                        marginRight: "4px",
                                        color: "white",
                                        fontSize: "8px",
                                        fontWeight: "bold",
                                      }}>
                                      {label}
                                    </span>
                                  ))
                                }
                              </div>
                            ),
                            key: 0,
                          }],
                        }}>
                        <span>+ {omittedValues?.length}</span>
                      </Dropdown>
                    )}
                    dropdownStyle={{ display: "none" }}
                    removeIcon={false}
                    suffixIcon={null}
                    showSearch={false}
                    tagRender={(props: any) => {
                      const { label } = props;
                      return (
                        <span
                          style={{
                            padding: "4px",
                            backgroundColor: user?.tags?.find((item: any) => item?.name === label)?.color || "gray",
                            borderRadius: "4px",
                            margin: "2px",
                            color: "white",
                            fontSize: "8px",
                            fontWeight: "bold",
                          }}>
                          {label}
                        </span>
                      );
                    }}
                  />
                ) : null
              }
              <div className="d-flex justify-content-center align-items-center">
                {
                  user?.type == 'COMMENT' ? (
                    <CommentOutlined style={{fontSize: 20, color: user?.readed ? '' : 'red',}}/>
                    // <i style={{fontSize: 24, color: user?.readed ? '' : 'red', marginTop: 2}} className="bx bx-comment"></i>
                  ) : (
                    <i style={{fontSize: 24, color: user?.readed ? '' : 'red', marginTop: 2}} className="bx bx-envelope"></i>
                  )
                }
              </div>
            </div>
          </div>
          {unRead && unRead !== 0 ? (
            <div className="ms-auto">
              <span className="badge badge-soft-dark rounded p-1">
                {unRead}
              </span>
            </div>
          ) : null}
        </div>
      </a>
    </li>
  );
};

export default styled(ChatUser)`
  .tag-select {
    .ant-select-selector {
      border: none !important;
      background: transparent !important;
      box-shadow: none !important;
      padding: 0 !important;
      padding-inline: 0 !important;
    }
  }
  .snippet {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 0;
  }
`
