import React, { useRef, useState } from 'react';
import { DatePicker, Slider, Button, Row, Col, Space, Divider } from 'antd';
import { endOfDay, endOfMonth, endOfWeek, endOfYear, set, startOfDay, startOfMonth, startOfWeek, startOfYear, subMonths, subWeeks, subYears } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import styled from 'styled-components';
import Column from './components/Column';
import FilterRangeButton from './components/FilterRangeButton';
import { DATE_FILTER } from '../../constants/dateFilter';

const { RangePicker } = DatePicker;

const CustomSlider = styled(Slider)`
  .ant-slider-track {
    background-color: #f5f5f5;
  }

  &:hover .ant-slider-track {
    background-color: #f5f5f5;
  }

  .ant-slider-rail {
    background-color: #91caff;
  }

  &:hover .ant-slider-rail {
    background-color: #91caff;
  }
`;

interface DateTimeRangePickerProps {
  onSubmit: (startDate: Dayjs, endDate: Dayjs) => void;
  startDate: Dayjs;
  setStartDate: any;
  endDate: Dayjs;
  setEndDate: any;
}

const DateTimeRangePickerComponent: React.FC<DateTimeRangePickerProps> = ({ onSubmit,startDate,setStartDate,endDate,setEndDate }) => {
  const dateToDayjs = (date: any) => dayjs(date);

  const [open, setOpen] = useState(false);

  const [tempStartDate, setTempStartDate] = useState<Dayjs>(dateToDayjs(startOfDay(new Date())));
  const [startDateHour, setStartDateHour] = useState<number>(0);

  const [tempEndDate, setTempEndDate] = useState<Dayjs>(dateToDayjs(endOfDay(new Date())));
  const [endDateHour, setEndDateHour] = useState<number>(23);

  const [dateFilter, setDateFilter] = useState<string>('');

  const formatStartTime = (time: number): string => {
    const minutes = '00';
    return time < 10 ? `0${time}:${minutes}` : `${time}:${minutes}`;
  };

  const formatEndTime = (time: number): string => {
    const minutes = '59';
    return time < 10 ? `0${time}:${minutes}` : `${time}:${minutes}`;
  };

  const disabledDate = (current: Dayjs | null): boolean => {
    return !!current && current > dayjs().endOf('day');
  };

  const handleOkClick = () => {
    onSubmit(tempStartDate, tempEndDate);
    setOpen(false);
  };

  const handleStartDateHourChange = (value: number) => {
    if (value < endDateHour + 1) {
      let updatedStartDate = startDate.hour(value).minute(0);
      setStartDate(updatedStartDate);
      setTempStartDate(updatedStartDate);
      setStartDateHour(value);
    }
  };

  const handleEndDateHourChange = (value: number) => {
    if (value > startDateHour - 1) {
      let updatedEndDate = endDate.hour(value).minute(59);
      setEndDate(updatedEndDate);
      setTempEndDate(updatedEndDate);
      setEndDateHour(value);
    }
  };

  const setRangeTime = (start:Dayjs,end:Dayjs) => {
    setStartDate(start);
    setTempStartDate(start);
    setEndDate(end);
    setTempEndDate(end);
    setStartDateHour(0);
    setEndDateHour(23);
  }

  const renderFooter = () => (
    <Col>
      {/*-------------------------- Slider  --------------------------*/}
      <Row gutter={36} className='px-2'>
        <Col span={12}>
          <Row align={"middle"}>
            <Col span={19} style={{ paddingLeft: 10 }}>
              <CustomSlider
                min={0}
                max={23}
                value={startDateHour}
                onChange={handleStartDateHourChange}
                step={1}
              /></Col>
            <Col span={5} style={{ textAlign: "right" }}>{formatStartTime(startDateHour)}</Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row align={"middle"}>
            <Col span={5}>{formatEndTime(endDateHour)}</Col>
            <Col span={19} style={{ paddingRight: 10 }}>
              <Slider
                min={0}
                max={23}
                value={endDateHour}
                onChange={handleEndDateHourChange}
                step={1}
              /></Col>
          </Row>
        </Col>
      </Row>
      {/*-------------------------- Option Button  --------------------------*/}
      <Divider style={{ margin: 0 }} />
      <Row gutter={16} className='px-4'>
        <Column title={'Theo ngày'}>
          <FilterRangeButton title={DATE_FILTER.TODAY.label} isActive={DATE_FILTER.TODAY.value == dateFilter}
            onClick={() => {
              setDateFilter(DATE_FILTER.TODAY.value);
              let startToday = dateToDayjs(startOfDay(new Date()));
              let endToday = dateToDayjs(endOfDay(new Date()));
              setRangeTime(startToday,endToday);
            }} />
          <FilterRangeButton title={DATE_FILTER.YESTERDAY.label} isActive={DATE_FILTER.YESTERDAY.value == dateFilter}
            onClick={() => {
              setDateFilter(DATE_FILTER.YESTERDAY.value);
              let startYesterday = dateToDayjs(startOfDay(new Date())).subtract(1, 'day');
              let endYesterday = dateToDayjs(endOfDay(new Date())).subtract(1, 'day');
              setRangeTime(startYesterday,endYesterday);
            }} />
        </Column>
        <Column title={'Theo tuần'}>
          <FilterRangeButton title={DATE_FILTER.THIS_WEEK.label} isActive={DATE_FILTER.THIS_WEEK.value == dateFilter}
            onClick={() => {
              setDateFilter(DATE_FILTER.THIS_WEEK.value);
              let mondayOfThisWeek = dateToDayjs(startOfDay(startOfWeek(new Date(), { weekStartsOn: 1 })));
              let today = dateToDayjs(endOfDay(new Date()));
              setRangeTime(mondayOfThisWeek,today);
            }} />
          <FilterRangeButton title={DATE_FILTER.LAST_WEEK.label} isActive={DATE_FILTER.LAST_WEEK.value == dateFilter}
            onClick={() => {
              setDateFilter(DATE_FILTER.LAST_WEEK.value);
              let mondayOfLastWeek = dateToDayjs(startOfDay(startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 })))
              let sundayOfLastWeek = dateToDayjs(endOfDay(endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 })))
              setRangeTime(mondayOfLastWeek,sundayOfLastWeek);
            }} />
          <FilterRangeButton title={DATE_FILTER.LAST_7_DAYS.label} isActive={DATE_FILTER.LAST_7_DAYS.value == dateFilter}
            onClick={() => {
              setDateFilter(DATE_FILTER.LAST_7_DAYS.value);
              let startLast7Days = dateToDayjs(startOfDay(new Date())).subtract(7, 'day');
              let endLast7Days = dateToDayjs(endOfDay(new Date()));
              setRangeTime(startLast7Days,endLast7Days);
            }} />
        </Column>
        <Column title={'Theo tháng'}>
          <FilterRangeButton title={DATE_FILTER.THIS_MONTH.label} isActive={DATE_FILTER.THIS_MONTH.value == dateFilter}
            onClick={() => {
              setDateFilter(DATE_FILTER.THIS_MONTH.value);
              let startThisMonth = dateToDayjs(set(new Date(), { date: 1 }));
              let endThisMonth = dateToDayjs(endOfDay(new Date()));
              setRangeTime(startThisMonth,endThisMonth);
            }} />
          <FilterRangeButton title={DATE_FILTER.LAST_MONTH.label} isActive={DATE_FILTER.LAST_MONTH.value == dateFilter}
            onClick={() => {
              setDateFilter(DATE_FILTER.LAST_MONTH.value);
              let startLastMonth = dateToDayjs(startOfDay(startOfMonth(subMonths(new Date(), 1))));
              let endLastMonth = dateToDayjs(endOfDay(endOfMonth(subMonths(new Date(), 1))));
              setRangeTime(startLastMonth,endLastMonth);
            }} />
          <FilterRangeButton title={DATE_FILTER.LAST_30_DAYS.label} isActive={DATE_FILTER.LAST_30_DAYS.value == dateFilter}
            onClick={() => {
              setDateFilter(DATE_FILTER.LAST_30_DAYS.value);
              let startLast30Days = dateToDayjs(startOfDay(new Date())).subtract(30, 'day');
              let today = dateToDayjs(endOfDay(new Date()));
              setRangeTime(startLast30Days,today);
            }} />
          <FilterRangeButton title={DATE_FILTER.LAST_60_DAYS.label} isActive={DATE_FILTER.LAST_60_DAYS.value == dateFilter}
            onClick={() => {
              setDateFilter(DATE_FILTER.LAST_60_DAYS.value);
              let startLast60Days = dateToDayjs(startOfDay(new Date())).subtract(60, 'day');
              let today = dateToDayjs(endOfDay(new Date()));
              setRangeTime(startLast60Days,today);
            }} />
          <FilterRangeButton title={DATE_FILTER.LAST_90_DAYS.label} isActive={DATE_FILTER.LAST_90_DAYS.value == dateFilter}
            onClick={() => {
              setDateFilter(DATE_FILTER.LAST_90_DAYS.value);
              let startLast90Days = dateToDayjs(startOfDay(new Date())).subtract(90, 'day');
              let today = dateToDayjs(endOfDay(new Date()));
              setRangeTime(startLast90Days,today);
            }} />
        </Column>
        <Column title={'Theo năm'}>
          <FilterRangeButton title={DATE_FILTER.THIS_YEAR.label} isActive={DATE_FILTER.THIS_YEAR.value == dateFilter}
            onClick={() => {
              setDateFilter(DATE_FILTER.THIS_YEAR.value);
              let startThisYear = dateToDayjs(set(new Date(), { month: 0, date: 1 }));
              let today = dateToDayjs(endOfDay(new Date()));
              setStartDate(startThisYear);
              setEndDate(today);
            }} />
          <FilterRangeButton title={DATE_FILTER.LAST_YEAR.label} isActive={DATE_FILTER.LAST_YEAR.value == dateFilter}
            onClick={() => {
              setDateFilter(DATE_FILTER.LAST_YEAR.value);
              let startLastYear = dateToDayjs(startOfDay(startOfYear(subYears(new Date(), 1))));
              let endLastYear = dateToDayjs(endOfDay(endOfYear(subYears(new Date(), 1))));
              setRangeTime(startLastYear,endLastYear);
            }} />
        </Column>
      </Row>
      <Row justify="end" style={{ paddingBottom: 16, paddingRight: 16 }}>
        <Button type="primary" onClick={handleOkClick} >OK</Button>
      </Row>
    </Col>
  );


  const handleOpenChange = (isOpen:any) => {
    if (isOpen) {
      setOpen(isOpen);
    }
  }

  return (
      <RangePicker
        open={open}
        onOpenChange={handleOpenChange}
        disabledDate={disabledDate}
        value={[startDate, endDate]}
        onCalendarChange={(dates: any) => {
          if (dates || dates.length == 2) {
            setStartDate(dates[0].hour(startDateHour).minute(0));
            setTempStartDate(dates[0].hour(startDateHour).minute(0));
            setEndDate(dates[1].hour(endDateHour).minute(59));
            setTempEndDate(dates[1].hour(endDateHour).minute(59));
            setDateFilter('');
          }
        }}
        format="DD/MM/YYYY HH:mm"
        renderExtraFooter={renderFooter}
      />
  );
};

export default DateTimeRangePickerComponent;
