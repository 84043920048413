import React, { useState, useEffect } from "react";
import moment from "moment";

// hooks
import { Button, Select, AutoComplete } from 'antd';
// apis
import styled from "styled-components";
import { useRedux } from "../../../../hooks";
import { CloseOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

import AddTagModal from "../../../Tags/AddTagModal";
import { createSelector } from "reselect";
import { addTag, getTagListAll } from "../../../../api/tags";
import { updateConversationSelect, updateTagList } from "../../../../redux/chats/actions";
import { addTagForConversation } from "../../../../api/conversations";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface IndexProps {
  className?: string;
}

const { Option } = Select;

const ConversationConfig = (props: IndexProps) => {
  const navigate = useNavigate();

  const { dispatch, useAppSelector } = useRedux();
    const today = moment().format('DD/MM');

    const state = createSelector(
      (props: any) => props.Chats,
      (props) => ({
        selectedChat: props.selectedChat,
        channelSelected: props.channelSelected,
        selectedConversation: props.selectedConversation,
        tagList: props.tagList,
      })
    );
  
    // Inside your component
    const { channelSelected, selectedConversation, selectedChat  } = useAppSelector(state);


    const [isShowAddTag, setIsShowAddTag] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [valueInput, setValueInput] = useState<string>('');
    const [tagList, setTagList] = useState<any>(null);


    const onSelect = async(name: string, data: any) => {
      const tagIds = selectedConversation.tags?.map((_item: any) => _item?.id);
      const responseAddTag: any = await addTagForConversation(selectedChat, {
        tags: tagIds?.concat(data?.key),
      });
      if(responseAddTag?.success) {
        dispatch(updateConversationSelect({
          ...selectedConversation,
          tags: selectedConversation?.tags?.concat(data?.item)
        }));
      }
      setValueInput('')
    };

    const onOpen = () => {
      setIsOpenModal(true);
    };
  
    const onClose = () => {
      setIsOpenModal(false);
    };
  
    const onSuccess = () => {
      setIsOpenModal(false);
    };

    const onRemoveTag = async(id: any) => {
      const tagIds = selectedConversation.tags?.map((_item: any) => _item?.id)
      const responseAddTag: any = await addTagForConversation(selectedChat, {
        tags: tagIds.filter((item: any) => item !== id),
      });
      if(responseAddTag?.success) {
        dispatch(updateConversationSelect({
          ...selectedConversation,
          tags: selectedConversation?.tags?.filter((item: any) => item?.id !== id)
        }));
      }
    }

    const onAddTagToday = async (name: string, color: any, sequence: number) => {
      const tagToday = tagList?.find((item: any) => item?.name === today)
      if(tagToday) {
        const tagIds = selectedConversation.tags?.map((_item: any) => _item?.id)
        const responseAddTag: any = await addTagForConversation(selectedChat, {
          tags: tagIds?.concat(tagToday.id),
        });
        if(responseAddTag?.success) {
          dispatch(updateConversationSelect({
            ...selectedConversation,
            tags: selectedConversation?.tags?.concat(tagToday)
          }));
        }
      } else {
        const response: any = await addTag(selectedConversation?.channelId, {
          name: name,
          color: color,
          sequence: sequence,
        });

        if(response?.success) {

          dispatch(updateTagList(tagList?.concat(response?.data)));

          const tagIds = selectedConversation.tags?.map((_item: any) => _item?.id)
          // Add Tag to conversation
          const responseAddTag: any = await addTagForConversation(selectedChat, {
            tags: tagIds?.concat(response?.data?.id),
          });
          if(responseAddTag?.success) {
            dispatch(updateConversationSelect({
              ...selectedConversation,
              tags: selectedConversation?.tags?.concat(response?.data)
            }));
          }
        } else {
          toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
        }
      }
    }

    const getTagListData = async (data: any) => {
      try {
        const response: any = await getTagListAll({ isAll: true, channelId: data?.channelId });
        if (response?.data?.items?.length > 0) {
          setTagList(response.data?.items?.sort((a: any, b: any) => a.sequence - b.sequence));
        } else {
          setTagList(null);
        }
      } catch (error) {
        console.log("error", error);
      }
    }


    useEffect(() => {
      getTagListData({
        channelId: selectedConversation?.channelId || '',
      });
     }, [selectedConversation?.channelId])
  
  return (
    <div className={props.className}>
      <div className="px-3">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <div className="title">Nhãn</div>
          <div onClick={() =>   navigate(`/setting/tags?channelId=${channelSelected}`)} className="manage_task">Quản lý thẻ</div>
        </div>

        <div className="list_tag">
            {
              selectedConversation?.tags?.length > 0 && selectedConversation?.tags?.map((item: any) => (
                <div key={item?.id} className="tag_item" style={{ backgroundColor: item?.color }}>
                  {item?.name}
                  <CloseOutlined onClick={() => {onRemoveTag(item?.id)}} style={{ cursor: 'pointer', marginLeft: 10 }} />
                </div>
              ))
            }
        </div>
        <Button className="add_tag mb-2" 
          onClick={() => setIsShowAddTag(!isShowAddTag)}
        >
          {isShowAddTag ? <MinusCircleOutlined /> : <PlusCircleOutlined />} Thêm thẻ
        </Button>
        {
          isShowAddTag && (
            <div>
              <AutoComplete
                value={valueInput}
                filterOption={true}
                children={tagList?.map((item: any) => {
                  if(!selectedConversation?.tags?.find((itemTagSelected: any) => itemTagSelected?.id === item?.id)) {
                    return (
                      <Option key={item?.id} value={item?.name} item={item}>
                        <div>
                          <div style={{ backgroundColor: item?.color, width: 20, height: 20, borderRadius: 5, marginRight: 10, display: 'inline-block' }}></div>
                          {item?.name}
                        </div>
                      </Option>
                    )
                  }
                })}
                style={{ width: 200 }}
                onSelect={onSelect}
                onSearch={(value) => setValueInput(value)}
                placeholder="Thêm thẻ"
              />
            </div>
          )
        }
        
        {
          (channelSelected?.split(',').length > 1) ? null : selectedConversation?.tags?.find((item: any) => item?.name === today) ? null : (
            <div className="mt-4">
              <div className="mb-2">Thẻ gợi ý </div>
              <button 
                onClick={()=> {
                onAddTagToday(today, '#1890ff', 1)
                }} 
              className="tag_today d-flex">Ngày hôm nay ({today})</button>
            </div>
          )
        }
      </div>

      {isOpenModal && (
        <AddTagModal
          isOpen={isOpenModal}
          onClose={onClose}
          onSuccess={onSuccess}
          channelSelected={channelSelected}
          tagLength={0}
          tagSelected={null}
          addMultiple
        />
      )}

    </div>
  );
};



export default styled(ConversationConfig)`
    height: 100%;
    .list_tag {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
      .tag_item {
        cursor: pointer;
        text-decoration: none;
        display: inline-block;
        padding: 5px 10px;
        border-radius: 5px;
        margin: 3px 3px !important;
        color: #fff;
        font-size: 14px;
      }
    }
    .title {
      font-size: 16px;
      font-weight: 600;
    }
    .add_tag {
      background-color: #f5f5f5;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
    }
    .manage_task {
      color: #1890ff;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .tag_today {
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 14px;
      background-color: #1890ff;
      color: #fff;
      border: none;
    }
`