import React, { useEffect, useState } from "react";
import classnames from "classnames";

// hooks
import { useRedux } from "../../hooks/index";
import { createSelector } from "reselect";

// component
import SideMenu from "../../layouts/Default/SideMenu";
import Chats from "./Chats/index";
import ConversationUser from "./ConversationUser/index";
import Order from "./Order/index";
import Welcome from "./ConversationUser/Welcome";
import { client } from "../../socket";
import { useDispatch } from "react-redux";
import {
  onReadedMess,
  onReceiveReaction,
  updateConversationList,
  uploadListImageDraft,
  updateTagsInConversation,
} from "../../redux/actions";
import { checkApplication } from "../../utils/checkApplication";
import pattern05 from '../../assets/images/bg-pattern/pattern-05.png';
import pattern06 from '../../assets/images/bg-pattern/pattern-06.png';
import { uploadMedia } from "../../api/chats";
import { toast } from "react-toastify";
import { Spin } from "antd";
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
const Index = () => {
  // global store
  const { useAppSelector } = useRedux();
  const dispatch = useDispatch();

  const data = createSelector(
    (state: any) => state.Chats,
    (state) => ({
      selectedChat: state.selectedChat,
      channelSelected: state.channelSelected,
      listImageDraft: state.listImageDraft,
      isLoadingConversationList: state.isLoadingConversationList,
      conversationList: state.conversationList,
    })
  );
  // Inside your component
  const { selectedChat, channelSelected, listImageDraft, isLoadingConversationList, conversationList } = useAppSelector(data);
  const [isDragging, setIsDragging] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };



  useEffect(() => {
    function onConnect() {
      console.log("Connected",)
    }

    function onDisconnect() {
      console.log("Disconnected")
      setTimeout(() => {
        client.connect();
      }, 5000);
    }

    function onError(ctx: any) {
      console.log("onError Socket", ctx)
      try {
        if (ctx?.error?.code === 2) {
          setTimeout(() => {
            client.connect();
          }, 5000);
        }
      } catch (error) {
        console.log("error", error)
      }
    }

    function onEvent(ctx: any) {
      console.log("onEvent ctx", ctx)
      if (ctx?.data?.type === "INBOX") {
        const messages = ctx.data?.data?.messages;
        messages?.forEach((element: any) => {
          if (channelSelected.includes(element.channelId)) {
            dispatch(updateConversationList({
              element,
              isPageToUser: channelSelected.includes(element?.from?.id)
            }));
          }
        });
      }
      if (ctx?.data?.type === "INBOX_READED") {
        const data = ctx?.data?.data;
        if (ctx?.data?.data?.conversationId === selectedChat && channelSelected.includes(data?.channelId)) { // chỉ xử lý khi đang ở trong cuộc trò chuyện active
          dispatch(onReadedMess({
            channelId: data?.channelId,
            conversationId: data?.conversationId,
            messageId: data?.mids?.[0],
          }));
        }
      }

      if (ctx?.data?.type === "INBOX_REACTION") {
        const data = ctx?.data?.data;
        if (ctx?.data?.data?.conversationId === selectedChat && channelSelected.includes(data?.channelId)) { // chỉ xử lý khi đang ở trong cuộc trò chuyện active
          dispatch(onReceiveReaction({
            channelId: data?.channelId,
            conversationId: data?.conversationId,
            messageId: data?.messageId,
            reaction: data?.reaction,
          }));
        }
      }

      if (ctx?.data?.type === "CONVERSATION_TAG") {
        const data = ctx.data?.data;
        if (
          conversationList?.items?.length > 0 &&
          conversationList?.items?.find((conversation: any) => conversation?.conversationId === data?.conversationId && conversation?.tags?.length !== data?.tags?.length)
        ) {
          dispatch(updateTagsInConversation(data));
        }
      }
    }

    client.on('connected', onConnect);

    client.on('disconnected', onDisconnect);

    client.on('error', onError);

    client.on('publication', onEvent);


    client.connect();

    return () => {
      client.off('connected', onConnect);
      client.off('disconnected', onDisconnect);
      client.off('publication', onEvent);
    };
  }, [channelSelected, selectedChat]);

  useEffect(() => {
    return () => {
      dispatch(uploadListImageDraft([]));
    }
  }, [])

  const appKey: number = checkApplication();

  const onUploadImage = (files: any) => {
    let listImageDraftNew = [...listImageDraft];
    Object.keys(files).forEach(async (key: any) => {
      const file = files[key]
      const formData = new FormData()
      formData.append('files', file);
      const response: any = await uploadMedia(formData);
      if (response.success) {
        listImageDraftNew.push(response.data?.[0])
        dispatch(uploadListImageDraft(listImageDraftNew));
        toast.success("Đã tải lên 1 ảnh thành công")
      }
    });
  }

  const handlePaste = (e: any) => {
    if (selectedChat) {
      onUploadImage(e.clipboardData.files)
    }
  }

  const handleDragOver = (event: any) => {
    event.preventDefault();
    if (!isDragging) {
      setIsDragging(true);
    }
  };

  const handleDrop = async (event: any) => {
    event.preventDefault();
    if (isDragging) {
      setIsDragging(false);
    }
    const files = event.dataTransfer.files;
    if (selectedChat) {
      onUploadImage(files)
    }
  };

  const handleDragLeave = (event: any) => {
    event.preventDefault();
    if (isDragging) {
      setIsDragging(false);
    }
  };

  return (
    <div className="d-flex w-100"
      onPaste={handlePaste}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragLeave={handleDragLeave}
    >
      <SideMenu />
      <Spin spinning={isLoadingConversationList}>
        <div className="chat-leftsidebar">
          <div className="content-leftsidebar">
            <Chats />
          </div>
        </div>
      </Spin>
      <div
        className={classnames("user-chat", "w-100", "overflow-hidden", {
          "user-chat-show": selectedChat,
        })}
        id="user-chat"
        style={{
          backgroundImage: `url(${appKey === 1 ? pattern06 : pattern05})`,
          border: (isDragging && selectedChat !== null) ? '3px dashed #4652d7' : '2px solid transparent',
          background: (isDragging && selectedChat !== null) ? '#ccc' : 'none',
          opacity: (isDragging && selectedChat !== null) ? 0.5 : 1,
        }}
      >

        {selectedChat !== null ? (
          <div className="chat-content d-lg-flex">
            <div className="w-100 overflow-hidden position-relative">
              <ConversationUser />
            </div>
            <div style={{ cursor: 'pointer', textAlign: 'center', alignItems: 'center', display: 'flex', marginRight: -6 }} onClick={toggleVisibility}>
              {!isVisible ? <CaretLeftOutlined style={{ color: 'rgb(13, 90, 255)', fontSize: 30 }} /> : <CaretRightOutlined style={{ color: 'rgb(13, 90, 255)', fontSize: 30 }} />}
            </div>
            {!isVisible ? "" : <div>
              <Order />
            </div>}

            {/* {showOrder ? <Order /> :""} */}
          </div>
        ) : (
          <Welcome />
        )}
      </div>
    </div>
  );
};

export default Index;
