import React, { useCallback, useEffect, useState } from "react";
import {
} from "reactstrap";
import { useRedux } from "../../../hooks/index";

//images
import iconTranslate from "../../../assets/images/icon_translate.png";
import { onTranslateText, onUpdateTranslationMessageId } from "../../../api/chats";
import { createSelector } from "reselect";

// utils

interface MessageProps {
  message: any;
  isFromMe: boolean;
}
const MessageTranslate = ({
  message,
  isFromMe,
}: MessageProps) => {
    const { useAppSelector } = useRedux();


    const data = createSelector(
        (state : any) => state.Chats,
        (state) => ({
          selectedChat: state.selectedChat,
          selectedConversation: state.selectedConversation,
    
        })
      );
      // Inside your component
      const { selectedChat, selectedConversation } = useAppSelector(data);

  

  const [textTranslate, setTextTranslate] = useState<any>();


  const getTextTranslate = async(text: any, translateReceivedTo: any, selectedChat: any, translations: any) => {
    const textTransalte = translations?.find((item: any) => item.language === translateReceivedTo)?.message;
    if(!textTransalte && text && text !== "" && text !== undefined) {
        if(message?.messageId) {
            const payloadUpdate = {
                messageId: message?.messageId,
                language: translateReceivedTo,
                conversationId:  selectedChat,
            };
            const resUpdateTransaltionMesss: any = await onUpdateTranslationMessageId(payloadUpdate);

              if(resUpdateTransaltionMesss?.success) {
                  setTextTranslate(resUpdateTransaltionMesss?.data?.translated?.translated || undefined);
              }
        } else { // trường hợp vừa ấn on send message
          const payload = {
            message: text,
            language: translateReceivedTo,
          }
          const res: any = await onTranslateText(payload);
          if(res?.success) {
              setTextTranslate(res?.data?.translated?.translated || undefined);
          }
        }
    } else {
      setTextTranslate(textTransalte)
    }
  }

  useEffect(() => {
    if(selectedConversation?.extraInfo?.translation) {
        getTextTranslate(message?.text, selectedConversation?.extraInfo?.translation?.translateReceivedTo, selectedChat, message?.extraInfo?.translation?.translations);
    }
  }, [isFromMe, selectedChat, 
      selectedConversation?.extraInfo?.translation, 
      selectedConversation?.extraInfo?.translation,  message?.messageId, message?.text,
    ]);
  return (
    (selectedConversation?.extraInfo?.translation && message.text && textTranslate) && (
        <p className="text-translate mb-0" style={isFromMe ? {color: 'red', textAlign: 'left'} : {color: 'red'}}><span><img style={{width: 17, marginRight: 4}} src={iconTranslate} alt="iconTranslate" className="iconTranslate" /></span>{textTranslate}</p>
    )
  );
};

export default MessageTranslate;
