import { takeEvery, fork, put, all, call , select} from "redux-saga/effects";
import { v4 as uuidv4 } from 'uuid';

// Login Redux States
import { ChatsActionTypes } from "./types";
import { changeIsLoadConversation, chatsApiResponseSuccess, chatsApiResponseError, changeSelectedConversation, onReadedMess, updateReplyMessage, onSendMessageByExtension  } from "./actions";

import {
  getChannels as getChannelsApi,
  createChannel as createChannelApi,
  getChatUserDetails as getChatUserDetailsApi,
  getChatUserConversations as getChatUserConversationsApi,
  sendMessage,
  onDeleteMessage as deleteMessageApi,
  receiveMessage as receiveMessageApi,
  readMessage as readMessageApi,
  getChannelDetails as getChannelDetailsApi,
  toggleFavouriteContact as toggleFavouriteContactApi,
  getArchiveContact as getArchiveContactApi,
  toggleArchiveContact as toggleArchiveContactApi,
  readConversation as readConversationApi,
  deleteImage as deleteImageApi,
  getMessageList,
  getConversationList as getConversationListApi,
  onUpdateTranslationMessageId,
  getDetailConversation,
} from "../../api/index";

import {
  showSuccessNotification,
  showErrorNotification,
} from "../../helpers/notifications";

//actions
import {
  getDirectMessages as getDirectMessagesAction,
  getFavourites as getFavouritesAction,
  getChannels as getChannelsAction,
} from "./actions";
import { selectorsExtensionReady, selectorsGetConversationList, selectorsGetMessagesHistories, selectorsGetReplyMessage, selectorsGetSelectedChat, selectorsGetSelectedConversation, selectorsIsSearchConversation } from "./selectors";
import { toast } from "react-toastify";
import { notification, message } from 'antd';


function sendMessageExtensions(data: any) {
  // Gửi message đến extension để Gửi tin nhắn
  try {
    const attachments: any =  data.attachments || [];
    const replyMessage: any = {};

    if (data.replyMessage) {
      replyMessage.message = data.replyMessage.text;
      replyMessage.timestamp = +new Date(data.replyMessage.createdAt);
    }
    console.log("messageIdExtention", data.messageIdExtention)
        window.postMessage({
          fb: {
            taskId: +new Date(),
            type: 'SEND_MESSAGE',
            pageId: data.conversation.pageId,
            threadId: data.conversation.threadKey.split('_')[1],
            //time: +new Date(responseData?.data?.lastMessageAt),
            time: +new Date(data.conversation.lastMessageAt),
            message: data.text || '',
            attachments,
            psid: data.conversation.from.customerId.split('_')[1],
            replyMessage: replyMessage.timestamp ? replyMessage : undefined,
            messageIdExtention: data.messageIdExtention,
          }
        }, '*');
  } catch (error) {
  console.log("🚀 ~ function*sendMessageExtensions ~ error:", error)
  }
}



function* getChannels() {
  try {
    const response: Promise<any> = yield call(getChannelsApi);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.GET_CHANNELS, response));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.GET_CHANNELS, error));
  }
}


function* createChannel({ payload: channelData }: any) {
  try {
    const response: Promise<any> = yield call(createChannelApi, channelData);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.CREATE_CHANNEL, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error);
    yield put(chatsApiResponseError(ChatsActionTypes.CREATE_CHANNEL, error));
  }
}

function* getChatUserDetails({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(getChatUserDetailsApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_CHAT_USER_DETAILS, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_CHAT_USER_DETAILS, error)
    );
  }
}

function* getChatUserConversations({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(getChatUserConversationsApi, id);
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS,
        response
      )
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS, error)
    );
  }
}

function* getMessagesHistories({ payload: data }: any): Generator<any, void, any>  {
  try {
    const response: Promise<any> = yield call(getMessageList, data.id, {page: data.page, limit: data.limit});
    const responseData = yield response;
    const messagesHistories = yield select(selectorsGetMessagesHistories);
    const messagesHistoriesNew = messagesHistories?.items && data?.page !== 1 ? [...responseData?.data?.items, ...messagesHistories?.items] : responseData?.data?.items;
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.GET_MESSAGES_HISTORIES,
        {
          total: responseData.data?.total,
          page: data?.page,
          items: messagesHistoriesNew,
          isGetMore: messagesHistories?.items && data?.page !== 1 ? true : false,
        },
      )
    );

    // Xử lý message đã đọc
    const seenMessages = messagesHistoriesNew?.filter((message: any) => (message.seen === true &&  message?.from?.id === message?.channelId));
    if(seenMessages) {
      const lastSeenMessage = seenMessages?.[seenMessages?.length - 1] || null;
      yield put(onReadedMess({
        channelId: lastSeenMessage?.channelId,
        conversationId: lastSeenMessage?.conversationId,
        messageId: lastSeenMessage?.messageId
      }));
    }


  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_MESSAGES_HISTORIES, error)
    );
  }
}

function* onSendMultiMsg({ payload: data }: any): Generator<any, void, any> {
  try {
    const extensionReady = yield select(selectorsExtensionReady)
    const selectedConversation = yield select(selectorsGetSelectedConversation)
    const messagesHistories = yield select(selectorsGetMessagesHistories)
    let messagesHistoriesNew = messagesHistories.items;
    const dataNew = {
      ...data,
      messageIdExtention: uuidv4(),
    }
    if (selectedConversation?.conversationId === data?.conversation?.conversationId) {
      messagesHistoriesNew.push(dataNew);
      yield put(chatsApiResponseSuccess(
        ChatsActionTypes.GET_MESSAGES_HISTORIES,
        {
          items: messagesHistoriesNew,
          total: messagesHistories.total,
          page: messagesHistories.page,
          isGetMore: false,
        }
      ))
    }
    console.log('Log Extension : ', extensionReady, data?.attachments)
    const lastMessage = messagesHistoriesNew.findLast((m: any)=> m.messageId);
    if (lastMessage.createdAt) {
      dataNew.conversation.lastMessageAt = lastMessage.createdAt
    }
    sendMessageExtensions(dataNew);
  } catch (error: any) {
    notification.error({
      message: "Lỗi",
      description: error,
      duration: 6,
    });
  }
}

function* onSendMessage({ payload: data }: any): Generator<any, void, any> {
  try {
    const extensionReady = yield select(selectorsExtensionReady)
    const selectedConversation = yield select(selectorsGetSelectedConversation)
    const messagesHistories = yield select(selectorsGetMessagesHistories)
    let messagesHistoriesNew = messagesHistories.items;
    const dataNew = {
      ...data,
      messageIdExtention: uuidv4(),
    }
    if (selectedConversation?.conversationId === data?.conversation?.conversationId) {
      messagesHistoriesNew.push(dataNew);
      yield put(chatsApiResponseSuccess(
        ChatsActionTypes.GET_MESSAGES_HISTORIES,
        {
          items: messagesHistoriesNew,
          total: messagesHistories.total,
          page: messagesHistories.page,
          isGetMore: false,
        }
      ))
    }
    const replyMessage = yield select(selectorsGetReplyMessage);
    let response: any;
    if(!replyMessage?.messageId){
      response = yield call(
        sendMessage,
        data.id,
        {
          action: data.typeMsg,
          message: data.text,
          contentId: data.contentId,
          replyToMessageId: data.refParentId,
        }
      );
    }
    if(!response?.success) { // Nếu send bị fail thì remove message vừa gửi hoặc tin nhắn reply thì cũng gửi qua extension
      if (((extensionReady && response?.data?.errorCode === 'MESSAGE_IS_BEING_SENT_OUTSIDE_THE_ALLOWED_WINDOW') || replyMessage?.messageId)) {
        notification.warning({
          message: "Đang gửi qua extension ...",
          duration: 3,
        });
        const lastMessage = messagesHistoriesNew.findLast((m: any)=> m.messageId);
        if (lastMessage.createdAt) {
          dataNew.conversation.lastMessageAt = lastMessage.createdAt
        }
        if (replyMessage) {
          dataNew.replyMessage = replyMessage;
        }
        sendMessageExtensions(dataNew);
        yield put(updateReplyMessage({}))
      } else {
        notification.error({
          message: "Lỗi",
          description: response?.data?.message || '',
          duration: 6,
        });
      }
      
      // remove message khi send fail 

      // messagesHistoriesNew = messagesHistoriesNew.slice(0, -1)
      // yield put(chatsApiResponseSuccess(
      //   ChatsActionTypes.GET_MESSAGES_HISTORIES,
      //   {
      //     items: messagesHistoriesNew,
      //     total: messagesHistories.total,
      //     page: messagesHistories.page,
      //     isGetMore: false,
      //   }
      // ))

    } else {
        //Call api thành công
        // Update lại messageId khi gửi message thành công
        if (selectedConversation?.conversationId === data?.conversation?.conversationId) {
          const messNew = { ...data, messageId: response?.data.message_id, };
          if (messagesHistoriesNew?.length > 0) {
            messagesHistoriesNew[messagesHistoriesNew.length - 1] = messNew;
          }
          yield put(chatsApiResponseSuccess(
            ChatsActionTypes.GET_MESSAGES_HISTORIES,
            {
              items: messagesHistoriesNew,
              total: messagesHistories.total,
              page: messagesHistories.page,
              isGetMore: false,
            }
          ))
        } else if (
          selectedConversation?.conversationId !== data?.conversation?.conversationId &&
          data?.to?.name && data?.typeMsg
        ) {
          const updatedMsgList = messagesHistoriesNew.map((msg: any) => {
            if (msg?.messageId === data?.refParentId) {
              return {
                ...msg,
                repliedPrivateMessageId: response?.data.message_id,
              };
            }
          
            return msg;
          });
          yield put(chatsApiResponseSuccess(
            ChatsActionTypes.GET_MESSAGES_HISTORIES,
            {
              items: updatedMsgList,
              total: messagesHistories.total,
              page: messagesHistories.page,
              isGetMore: false,
            }
          ))
          message.success(`Bạn vừa gửi tin nhắn tới ${data?.to?.name}`)
        }

        // Update converstaion list khi gửi message đi
        const conversationList = yield select(selectorsGetConversationList)
        const updatedConversationList = conversationList.items.map((conversation: any) => {
          if (conversation?.conversationId === data?.conversation?.conversationId) {
            return {
              ...conversation,
              snippet: data?.text,
              lastMessageAt: new Date().toISOString(),
            };
          }
        
          return conversation;
        });

        yield put(
          chatsApiResponseSuccess(
            ChatsActionTypes.GET_CONVERSATION_LIST,
            {
              total: conversationList.total,
              page: conversationList.page,
              items: updatedConversationList,
            },
          )
        );
    } 
  } catch (error: any) {
    notification.error({
      message: "Lỗi",
      description: error,
      duration: 6,
    });
  }
}

function* onDeleteMessage({ payload: data }: any): Generator<any, void, any> {
  try {
    const response: any = yield call(deleteMessageApi, data?.conversationId, data?.messageId);
    if(!response?.success) {
      notification.error({
        message: "Lỗi",
        description: response?.data?.message || '',
        duration: 6,
      });
    } else {
        // Update comment về đã xóa khi xóa comment thành công
        const messagesHistories = yield select(selectorsGetMessagesHistories)
        const updatedMsgList = messagesHistories.items.map((message: any) => {
          if (message?.messageId === data?.messageId) {
            return {
              ...message,
              isRemoved: true,
            };
          }
          return message;
        });
        console.log('oooo 9 : ', updatedMsgList)
        yield put(
          chatsApiResponseSuccess(
            ChatsActionTypes.GET_MESSAGES_HISTORIES,
            {
              total: messagesHistories.total,
              page: messagesHistories.page,
              items: updatedMsgList,
            },
          )
        );
    } 

  } catch (error: any) {
    notification.error({
      message: "Lỗi",
      description: error,
      duration: 6,
    });
  }
}
function* getConversationList({ payload: data }: any): Generator<any, void, any> {
  try {
    if (!data?.hasMore) {
      yield put(changeIsLoadConversation(true));
    }
    const response: Promise<any> = yield call(getConversationListApi, data);
    const responseData = yield response;
    const conversationList = yield select(selectorsGetConversationList);
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.GET_CONVERSATION_LIST,
        {
          total: responseData.data.total,
          page: data.page,
          items: conversationList?.items && data.page !== 1 ? [...conversationList?.items, ...responseData.data.items] : responseData.data.items,
        },
      )
    );
    if (!data?.hasMore) {
      yield put(changeIsLoadConversation(false));
    }
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_CONVERSATION_LIST, error)
    );
    if (!data?.hasMore) {
      yield put(changeIsLoadConversation(false));
    }
  }
}

function* onUpdateTagsInConversation({ payload: data }: any): Generator<any, void, any>  {
  try {
    // Update tags in converstaion trong danh sách conversation
    const conversationList = yield select(selectorsGetConversationList)
    const selectedConversation = yield select(selectorsGetSelectedConversation)
    let conversationListNew  = conversationList.items;
    const existingIndex = conversationListNew?.findIndex((item: any) => item?.conversationId === data?.conversationId);
    if (existingIndex !== -1) {
      const existingItem = conversationListNew.splice(existingIndex, 1)[0];
      conversationListNew.unshift({
        ...existingItem,
        tags: data?.tags,
      });
      yield put(
        chatsApiResponseSuccess(
          ChatsActionTypes.GET_CONVERSATION_LIST,
          {
            total: conversationList.total,
            page: conversationList.page,
            items: conversationListNew,
          },
        )
      );
      // Update tags ở chat input nếu cùng conversation
      if (selectedConversation?.conversationId === data?.conversationId) {
        yield put(changeSelectedConversation({
          ...selectedConversation,
          tags: data?.tags,
        }));
      }
    }
  } catch (error: any) {
    console.log("errr", error)
  }
}

function* onReceiveMessage({ payload: data }: any): Generator<any, void, any>  {
  try {
    // Update converstaion list
    const conversationList = yield select(selectorsGetConversationList)
    const selectedChat = yield select(selectorsGetSelectedChat);
    const isSearchConversation = yield select(selectorsIsSearchConversation);

    let conversationListNew  = conversationList.items;
    const existingIndex = conversationListNew?.findIndex((item: any) => item?.conversationId === data?.element?.conversation?.conversationId);
    if (existingIndex !== -1) {
      const existingItem = conversationListNew.splice(existingIndex, 1)[0];
      conversationListNew.unshift({
        ...existingItem,
        snippet: data.element.conversation.snippet,
        unreadCount: selectedChat == data?.element?.conversation?.conversationId ? 0 : data.element?.conversation?.unreadCount, // nếu ở converstaion đó đang active thì ko cộng unread
        readed: data?.isPageToUser ? true : false, 
        lastMessageAt: data?.element?.conversation?.lastMessageAt,
        from: {
          ...data?.element?.conversation?.from,
          avatar: data?.element?.conversation?.from?.avatar,
        },
      });
    } else {
      if(isSearchConversation) {
        console.log("User đang search nên ko đẩy conversaion mới khi nhận socket")
      } else {
        conversationListNew.unshift(data.element.conversation);
      }
    }
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.GET_CONVERSATION_LIST,
        {
          total: conversationList.total,
          page: conversationList.page,
          items: conversationListNew,
        },
      )
    );
    
    // Only update message history when user inbox

    const selectedConversation = yield select(selectorsGetSelectedConversation)
    const messagesHistories = yield select(selectorsGetMessagesHistories)
    if(selectedChat == data?.element?.conversation?.conversationId) {

      let messagesHistoriesNew = messagesHistories.items;
      // Nếu là page gửi cho user và đã tồn tại trong list message thì ko add vào nữa
      if(data?.isPageToUser && messagesHistories.items.filter((item: any) => item?.messageId === data?.element?.messageId).length > 0) {
        return;
      }
      // Case với extension : Nếu là page gửi cho user và đã tồn tại trong list message thì ko add vào nữa
      if(data?.isPageToUser && messagesHistories.items.filter((item: any) => item?.timestamp === new Date(data?.element?.createdAt).getTime()).length > 0) {
        console.log("Chặn ko add khi nhận socket vào mess list")
        return; 
      }

      // Cập nhật lại message id khi nhận message từ user
      let responseUpdateTranslation: any;
      if(data.element?.messageId) {
        const payload = {
          messageId: data.element?.messageId,
          language: selectedConversation?.extraInfo?.translation?.translateReceivedTo || "vi",
          conversationId: data.element?.conversationId || '',
        };
         responseUpdateTranslation = yield call(onUpdateTranslationMessageId, payload);
      } else {
        notification.error({ description: "Không có message id", message: "Lỗi", duration: 6 })
      }

      messagesHistoriesNew.push({
        ...data.element,
        extraInfo: responseUpdateTranslation ? {
          translation: {translations: responseUpdateTranslation?.data?.translations},
        } : {}
      });

      
      yield put(chatsApiResponseSuccess(
        ChatsActionTypes.GET_MESSAGES_HISTORIES,
        {
          items: messagesHistoriesNew,
          total: messagesHistories.total,
          page: messagesHistories.page,
          isGetMore: false,
        }
      ))


    } else {
      console.log("không đang ở conversation mà tin nhắn gửi về")
    }

  } catch (error: any) {
    console.log("errr", error)
  }
}
function* onReceiveReaction({ payload: data }: any): Generator<any, void, any>  {
  try {
    const messagesHistories = yield select(selectorsGetMessagesHistories)
    let messagesHistoriesNew = messagesHistories.items;
    messagesHistoriesNew = messagesHistoriesNew.map((item: any) => {
      if (item.messageId === data?.messageId) {
        return {
          ...item,
          reaction: data?.reaction,
        };
      }
      return item;
    });
    yield put(chatsApiResponseSuccess(
      ChatsActionTypes.GET_MESSAGES_HISTORIES,
      {
        items: messagesHistoriesNew,
        total: messagesHistories.total,
        page: messagesHistories.page,
        isGetMore: false,
      }
    ))

  } catch (error: any) {
    console.log("errr", error)
  }
}

function* updateConversationSelect({ payload: data }: any): Generator<any, void, any>  {
  try {
    const conversationList = yield select(selectorsGetConversationList)
    let conversationListNew  = conversationList.items;
    const existingIndex = conversationListNew?.findIndex((item: any) => item?.conversationId === data.conversationId);
    if (existingIndex !== -1) {
      conversationListNew.splice(existingIndex, 1, data);
      yield put(changeSelectedConversation(data));
    }
  } catch (error: any) {
    console.log("errr", error)
  }
}

function* receiveMessage({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(receiveMessageApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.RECEIVE_MESSAGE, response)
    );
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.RECEIVE_MESSAGE, error));
  }
}

function* readMessage({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(readMessageApi, id);
    yield put(chatsApiResponseSuccess(ChatsActionTypes.READ_MESSAGE, response));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.READ_MESSAGE, error));
  }
}




function* getChannelDetails({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(getChannelDetailsApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_CHANNEL_DETAILS, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_CHANNEL_DETAILS, error)
    );
  }
}

function* toggleFavouriteContact({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(toggleFavouriteContactApi, id);
    yield put(
      chatsApiResponseSuccess(
        ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT,
        response
      )
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(
      chatsApiResponseError(ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT, error)
    );
  }
}

function* getArchiveContact() {
  try {
    const response: Promise<any> = yield call(getArchiveContactApi);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.GET_ARCHIVE_CONTACT, response)
    );
  } catch (error: any) {
    yield put(
      chatsApiResponseError(ChatsActionTypes.GET_ARCHIVE_CONTACT, error)
    );
  }
}

function* toggleArchiveContact({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(toggleArchiveContactApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT, response)
    );
    yield call(showSuccessNotification, response + "");
  } catch (error: any) {
    yield call(showErrorNotification, error + "");
    yield put(
      chatsApiResponseError(ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT, error)
    );
  }
}

function* readConversation({ payload: id }: any) {
  try {
    const response: Promise<any> = yield call(readConversationApi, id);
    yield put(
      chatsApiResponseSuccess(ChatsActionTypes.READ_CONVERSATION, response)
    );
    yield put(getDirectMessagesAction());
    yield put(getFavouritesAction());
    yield put(getChannelsAction());
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.READ_CONVERSATION, error));
  }
}

function* deleteImage({ payload: { userId, messageId, imageId } }: any) {
  try {
    const response: Promise<any> = yield call(
      deleteImageApi,
      userId,
      messageId,
      imageId
    );
    yield put(chatsApiResponseSuccess(ChatsActionTypes.DELETE_IMAGE, response));
  } catch (error: any) {
    yield put(chatsApiResponseError(ChatsActionTypes.DELETE_IMAGE, error));
  }
}


function* onSendMessageExtensionSuccess({payload}: any): Generator<any, void, any>  {
  // Khi sendMessage thành công qua extenstion thì phải update timestamp vào list message
  try {
    const selectedConversation = yield select(selectorsGetSelectedConversation)
    const messagesHistories = yield select(selectorsGetMessagesHistories)
    console.log("vao day ne0 ", selectedConversation)
    console.log("payload", payload)
    if(selectedConversation?.pageId == payload?.request?.pageId) {
      console.log("vao day ne2 ", payload)
      let messagesHistoriesNew = messagesHistories.items;
      messagesHistoriesNew = messagesHistoriesNew.map((item: any) => {
        if (item.messageIdExtention === payload?.request?.messageIdExtention) {
          return {
            ...item,
            timestamp: payload?.timestamp,
          };
        }
        return item;
      });
      yield put(chatsApiResponseSuccess(
        ChatsActionTypes.GET_MESSAGES_HISTORIES,
        {
          items: messagesHistoriesNew,
          total: messagesHistories.total,
          page: messagesHistories.page,
          isGetMore: false,
        }
      ))
    }
  } catch (error: any) {
    // yield put(chatsApiResponseError(ChatsActionTypes.DELETE_IMAGE, error));
  }
}

export function* watchGetChannels() {
  yield takeEvery(ChatsActionTypes.GET_CHANNELS, getChannels);
}

export function* watchCreateChannel() {
  yield takeEvery(ChatsActionTypes.CREATE_CHANNEL, createChannel);
}
export function* watchGetChatUserDetails() {
  yield takeEvery(ChatsActionTypes.GET_CHAT_USER_DETAILS, getChatUserDetails);
}
export function* watchGetChatUserConversations() {
  yield takeEvery(
    ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS,
    getChatUserConversations
  );
}
export function* watchGetMessagesHistories() {
  yield takeEvery(
    ChatsActionTypes.GET_MESSAGES_HISTORIES,
    getMessagesHistories
  );
}
export function* watchGetConversationList() {
  yield takeEvery(
    ChatsActionTypes.GET_CONVERSATION_LIST,
    getConversationList
  );
}

export function* watchUpdateConversationList() {
  yield takeEvery(
    ChatsActionTypes.UPDATE_CONVERSATION_LIST,
    onReceiveMessage
  );
}

export function* watchUpdateTagsInConversation() {
  yield takeEvery(
    ChatsActionTypes.UPDATE_TAGS_IN_CONVERSATION,
    onUpdateTagsInConversation
  );
}

export function* watchUpdateConversationSelect() {
  yield takeEvery(
    ChatsActionTypes.UPDATE_CONVERSATION_SELECT,
    updateConversationSelect
  );
}

export function* watchReceiveReaction() {
  yield takeEvery(
    ChatsActionTypes.RECEIVE_REACTION,
    onReceiveReaction
  );
}
export function* watchOnSendMessageExtension() {
  yield takeEvery(ChatsActionTypes.ON_SEND_MESSAGE_BY_EXTENSION, onSendMessageExtensionSuccess);
}

export function* watchOnSendMessage() {
  yield takeEvery(ChatsActionTypes.ON_SEND_MESSAGE, onSendMessage);
}
export function* watchOnSendMultiMsg() {
  yield takeEvery(ChatsActionTypes.ON_SEND_MULTI_MESSAGE, onSendMultiMsg);
}
export function* watchReceiveMessage() {
  yield takeEvery(ChatsActionTypes.RECEIVE_MESSAGE, receiveMessage);
}
export function* watchReadMessage() {
  yield takeEvery(ChatsActionTypes.READ_MESSAGE, readMessage);
}

export function* watchOnDeleteMessage() {
  yield takeEvery(ChatsActionTypes.ON_DELETE_MESSAGE, onDeleteMessage);
}

export function* watchGetChannelDetails() {
  yield takeEvery(ChatsActionTypes.GET_CHANNEL_DETAILS, getChannelDetails);
}
export function* watchToggleFavouriteContact() {
  yield takeEvery(
    ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT,
    toggleFavouriteContact
  );
}
export function* watchGetArchiveContact() {
  yield takeEvery(ChatsActionTypes.GET_ARCHIVE_CONTACT, getArchiveContact);
}
export function* watchToggleArchiveContact() {
  yield takeEvery(
    ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT,
    toggleArchiveContact
  );
}
export function* watchReadConversation() {
  yield takeEvery(ChatsActionTypes.READ_CONVERSATION, readConversation);
}
export function* watchDeleteImage() {
  yield takeEvery(ChatsActionTypes.DELETE_IMAGE, deleteImage);
}

function* chatsSaga() {
  yield all([
    fork(watchGetChannels),
    fork(watchCreateChannel),
    fork(watchGetChatUserDetails),
    fork(watchGetChatUserConversations),
    fork(watchGetMessagesHistories),
    fork(watchGetConversationList),
    fork(watchOnSendMessage),
    fork(watchOnSendMultiMsg),
    fork(watchOnDeleteMessage),
    fork(watchGetChannelDetails),
    fork(watchToggleFavouriteContact),
    fork(watchGetArchiveContact),
    fork(watchToggleArchiveContact),
    fork(watchReadConversation),
    fork(watchDeleteImage),
    fork(watchUpdateConversationList),
    fork(watchUpdateTagsInConversation),
    fork(watchUpdateConversationSelect),
    fork(watchReceiveReaction),
    fork(watchOnSendMessageExtension),
  ]);
}

export default chatsSaga;
