import { APIClient } from "./apiCore";
import * as url from "./urls";

const api = new APIClient();

const getQuickRepliesList = (params: any) => {
    return api.get(url.QUICk_REPLIES, { params: params });
};

const addQuickReplies = (data: any) => {
    return api.create(url.QUICk_REPLIES, data);
};

const deleteQuickReplies = (quickRepliesId: string) => {
    return api.delete(url.QUICk_REPLIES + "/" + quickRepliesId);
};

const updateQuickReplies = (quickRepliesId: string, data: any) => {
    return api.update(url.QUICk_REPLIES + "/" + quickRepliesId, data);
};

export {
    getQuickRepliesList,
    addQuickReplies,
    deleteQuickReplies,
    updateQuickReplies,
};
