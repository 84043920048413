import { notification } from "antd";
import React, { useEffect, useState } from "react";
import { useRedux } from "../hooks/useRedux";
import { onHandleStatusExtension, onSendMessageByExtension } from "../redux/chats/actions";

const AppExtension = () => {
    const { dispatch } = useRedux();

    const checkExtensions = ()  => {
        window.postMessage({
          fb: {
            type: 'INIT',
          }
        }, '*');
    }

    useEffect(() => {
        checkExtensions();
    }, [])

    useEffect(() => {
        const handleAppExtension = (event: any) => {
            const extensionCallbackData = event?.data?.fb;
            if (extensionCallbackData) {
              console.log("addEventListener--------fb: ", extensionCallbackData);
              const { type, success, error } = extensionCallbackData;
              switch (type) {
                case "INIT_RESULT": 
                    console.log("EXTENSION READY", success )
                    dispatch(onHandleStatusExtension(success))
                    break;
                case "REACT_MESSAGE_RESULT":
                  if (!success) {
                    notification.error({
                      message: "Lỗi",
                      description: error || '',
                      duration: 3,
                    });
                  } else {
                    
                  }; 
                  break;
                case "SEND_MESSAGE_RESULT": 
                  if (!success) {
                    notification.error({
                      message: "Lỗi",
                      description: error || '',
                      duration: 3,
                    });
                  } else {
                    console.log("extensionCallbackData", extensionCallbackData)
                    // Xử lý update timestamp vào message vừa send
                    dispatch(onSendMessageByExtension(extensionCallbackData));
                  }
                  break;
                case "MAKE_PROFILE_LINK_RESULT":
                  if (!success) {
                    notification.error({
                      message: "Lỗi",
                      description: error || "",
                      duration: 3,
                    });
                  }
                  break;
          
                  case "MAKE_BUSINESS_LINK_RESULT":
                  if (!success) {
                    notification.error({
                      message: "Lỗi",
                      description: error || "",
                      duration: 3,
                    });
                  }
                  break;
              }
            }
        };
    
        window.addEventListener("message", handleAppExtension);
        return () => window.removeEventListener("message", handleAppExtension);
      }, []);

    return <React.Fragment></React.Fragment>
};

export default AppExtension;
