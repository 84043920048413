import React, { useEffect, useState, useContext, useMemo } from "react";

import classnames from "classnames";

// hooks
import {
  getTagListAll,
  deleteTag,
  updateTagAll,
} from "../../api/index";

// router
import { useSearchParams } from "react-router-dom";

// styles
import styled from "styled-components";

// component
import type { ColumnsType } from 'antd/es/table';
import { CSS } from '@dnd-kit/utilities';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Table, Modal, Input, message } from 'antd';
import {
  TagTwoTone,
  DeleteTwoTone,
  EditFilled,
  SearchOutlined,
  HolderOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  Row,
  Col,
} from "reactstrap";
import AddTagModal from "./AddTagModal/index";
import ModalSyncTag from "./ModalSyncTag/index";
import { toast } from "react-toastify";

interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: SyntheticListenerMap;
}

const RowContext = React.createContext<RowContextProps>({});

const DragHandle: React.FC = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{ cursor: 'move' }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};


interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const RowTable: React.FC<RowProps> = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props['data-row-key'] });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  const contextValue = useMemo<RowContextProps>(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners],
  );

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

interface IndexProps {
  className?: string;
}

const Index = (props: IndexProps) => {
  const { className } = props;
  const { confirm } = Modal;

  const [searchParams] = useSearchParams();
  const channelId = searchParams.get('channelId');
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [showModalSync, setShowModalSync] = useState<boolean>(false);
  const [tagList, setTagList] = useState<any>(null);
  const [tagSelected, setTagSelected] = useState<any>(null);
  const [listTag, setListTag] = useState<any[]>([]);
  const [valueInput, setValueInput] = useState<string>('');

  useEffect(() => {
    if (channelId) {
      getTagListData({
        channelId: channelId || '',
      });
    }
  }, [channelId]);

  const getTagListData = async (data: any) => {
    try {
      const response: any = await getTagListAll({ isAll: true, channelId: data?.channelId });
      if (response?.data?.items) {
        setTagList(response.data);
        if (valueInput === '') {
          setListTag(response.data.items?.sort((a: any, b: any) => a.sequence - b.sequence));
        } else {
          setListTag(response.data.items?.filter((item: any) => item.name.toLowerCase().includes(valueInput.toLowerCase()))?.sort((a: any, b: any) => a.sequence - b.sequence));
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const onOpen = () => {
    setIsOpenModal(true);
  };

  const onOpenUpdate = (tag: any) => {
    setTagSelected(tag);
    setIsOpenModal(true);
  };

  const onClose = () => {
    setIsOpenModal(false);
    setTagSelected(null);
  };

  const onSuccess = () => {
    setIsOpenModal(false);
    setTagSelected(null);
    getTagListData({
      channelId: channelId || '',
    });
  };

  const showConfirm = (tagId: string) => {
    confirm({
      title: 'Bạn có muốn xóa thẻ ?',
      icon: <ExclamationCircleFilled />,
      content: 'Bạn đang xoá thẻ hội thoại. Thẻ này sẽ bị xóa hoàn toàn khỏi các hội thoại và không thể khôi phục. Bạn có chắc chắn muốn xoá ?',
      async onOk() {
        try {
          const response: any = await deleteTag(channelId || '', tagId);
          if (response?.data && response?.success) {
            getTagListData({
              channelId: channelId || '',
            });
          } else {
            toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
          }
        } catch (error) {
          console.log("error", error);
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const searchTags = (e: any) => {
    const value = e.target.value;
    setValueInput(value);
    if (value === '') {
      setListTag(tagList?.items?.sort((a: any, b: any) => a.sequence - b.sequence));
    } else {
      setListTag(tagList?.items?.filter((item: any) => item.name.toLowerCase().includes(value.toLowerCase()))?.sort((a: any, b: any) => a.sequence - b.sequence));
    }
  };

  const columns: ColumnsType<any> = [
    { key: 'sort', align: 'center', width: 60, render: () => <DragHandle /> },
    {
      title: 'Tên thẻ',
      width: 300,
      render: (record) => (
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <TagTwoTone
            twoToneColor={record.color}
            style={{
              transform: 'rotate(270deg)',
              marginRight: 8,
              fontSize: 20,
            }}
          />
          <span>{record.name}</span>
        </div>
      ),
    },
    {
      title: 'Màu sắc',
      render: (record) => (
        <div style={{
          backgroundColor: record.color,
          width: 80,
          height: 20,
          borderRadius: 10,
        }} />
      ),
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: 100,
        }}>
          <div
            className="actions-tag"
            onClick={() => onOpenUpdate(record)}
          >
            <EditFilled
              style={{
                fontSize: 20,
                marginRight: 16,
              }}
            />
          </div>
          <div
            className="actions-tag"
            onClick={() => showConfirm(record?.id)}
          >
            <DeleteTwoTone
              twoToneColor='red'
              style={{
                fontSize: 20,
              }}
            />
          </div>
        </div>
      ),
    }
  ];

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active && over && active.id !== over.id) {
      const oldIndex = tagList?.items?.findIndex((item: any) => item.id === active.id);
      const newIndex = tagList?.items?.findIndex((item: any) => item.id === over.id);
      const newList = arrayMove(tagList?.items, oldIndex, newIndex).map((item: any, key: number) => ({...item, sequence: key}));
      setTagList({
        ...tagList,
        items: newList,
      });
      if (valueInput === '') {
        setListTag(newList);
      } else {
        setListTag(newList?.filter((item: any) => item.name.toLowerCase().includes(valueInput.toLowerCase())));
      }
      onUpdate(newList);
    }
  };

  const onUpdate = async (newList: any) => {
    try {
        const response: any = await updateTagAll(channelId || '', {
          tags: newList
        });
        if (response?.data && response?.success) {
          message.success('Cài đặt đã được cập nhật')
        } else {
            toast.error(response?.data?.message?.error || response?.data?.message || 'Có lỗi xảy ra');
        }
    } catch (error) {
        console.log("error", error);
    }
};

  return (
    <div
      className={classnames(className, "user-chat", "w-100", "overflow-hidden")}
      id="user-chat"
      style={{
        justifyContent: "center",
        display: "flex",
      }}
    >
      <div className="chat-content p-3 p-lg-4 w-80 h-100">
        <div className="p-3 bg-white" style={{ borderRadius: 8 }}>
          <div style={{ marginBottom: 16 }}>
            <span className="username" style={{ fontWeight: 'bold', fontSize: 15 }}>Thẻ hội thoại</span>
          </div>
          <Row className="align-items-center">
            <Col sm={6}>
              <Input
                placeholder="Tìm kiếm thẻ hội thoại"
                prefix={<SearchOutlined />}
                onChange={searchTags}
                value={valueInput}
              />
            </Col>
            <Col sm={6}>
              <ul className="list-inline user-chat-nav text-end mb-0 d-flex align-items-center justify-content-end">
                <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">
                  <div
                    onClick={onOpen}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#105CE2",
                      padding: "5px 10px",
                      borderRadius: 5,
                      height: 32,
                    }}
                  >
                    <span style={{ marginLeft: 4, color: 'white' }}>Thêm thẻ</span>
                  </div>
                </li>
                <li className="list-inline-item d-none d-lg-inline-block me-2 ms-0">
                  <div
                    onClick={() => setShowModalSync(true)}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#eaecf0",
                      padding: "5px 12px",
                      borderRadius: 5,
                      height: 32,
                    }}
                  >
                    <SyncOutlined />
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
          {isOpenModal && (
            <AddTagModal
              isOpen={isOpenModal}
              onClose={onClose}
              onSuccess={onSuccess}
              channelSelected={channelId || ''}
              tagLength={tagList?.total || 0}
              tagSelected={tagSelected}
              tagList={tagList?.items?.length > 0 ? tagList?.items : []}
            />
          )}
          {showModalSync && (
              <ModalSyncTag
                isOpen={showModalSync}
                onClose={() => setShowModalSync(false)}
                channelId={channelId || ''}
                allTag={tagList?.items}
              />
          )}
          {
            listTag?.length > 0 ? (
              <div
                className="bg-white"
                id="tag-list"
                style={{
                  borderRadius: 8,
                  marginTop: 16,
                  height: 'calc(100vh - 230px)',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  border: '1px solid #d0d0d0'
                }}>
                <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                  <SortableContext items={listTag.map((i, key) => i.id)} strategy={verticalListSortingStrategy}>
                    <Table
                      rowKey="key"
                      pagination={false}
                      components={{ body: { row: RowTable } }}
                      columns={columns}
                      dataSource={
                        listTag.map((tag, index) => ({
                          key: tag.id,
                          ...tag,
                        }))
                      }
                    />
                  </SortableContext>
                </DndContext>
              </div>
            ) : (
              <div
                className="bg-white"
                id="tag-list"
                style={{
                  borderRadius: 8,
                  marginTop: 16,
                  height: 'calc(100vh - 170px)',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  border: '1px solid #d0d0d0',
                  width: 606,
                }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    fontSize: 20,
                    color: '#d0d0d0',
                  }}
                >
                  Không có dữ liệu
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default styled(Index)`
  .actions-tag {
    display: none;
  }

  .ant-table-row:hover .actions-tag {
    display: block;
  }
`