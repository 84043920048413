// types
import { ChatsActionTypes, ChatsState } from "./types";

export const INIT_STATE: ChatsState = {
  favourites: [],
  directMessages: [],
  channels: [],
  selectedChat: null,
  replyMsgSelected: null,
  channelSelected: null,
  selectedConversation: {},
  groupInfo: {},
  pageInfo: {},
  chatUserDetails: {},
  chatUserConversations: {},
  messagesHistories: {},
  conversationList: {},
  isOpenUserDetails: false,
  channelDetails: {},
  archiveContacts: [],
  conversationSelected: null,
  listImageDraft: [],
  tagList: [],
  refreshConversation: Math.random(),
  messagReadedInconverstaion: {},
  isAddTagGroupSuccess: false,
  conversationFilterState: {
    isRead: true,
    isSpam: false,
    tags: [],
    type: ''
  },
  replyMessage: {},
  extensionReady: false,
  isLoadingConversationList: false,
  isSearchConversation: false,
};

const Chats = (state = INIT_STATE, action: any) => {
  switch (action.type) {
    case ChatsActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ChatsActionTypes.CREATE_CHANNEL:
          return {
            ...state,
            isChannelCreated: true,
            createChannelLoading: false,
          };
        case ChatsActionTypes.GET_CHAT_USER_DETAILS:
          return {
            ...state,
            chatUserDetails: action.payload.data,
            isUserDetailsFetched: true,
            getUserDetailsLoading: false,
          };
        case ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS:
          return {
            ...state,
            chatUserConversations: action.payload.data,
            isUserConversationsFetched: true,
            getUserConversationsLoading: false,
            isUserMessageSent: false,
            isMessageDeleted: false,
            isMessageForwarded: false,
          };
        case ChatsActionTypes.GET_MESSAGES_HISTORIES:
          return {
            ...state,
            messagesHistories: action.payload.data,
            getUserConversationsLoading: false,
          };
        case ChatsActionTypes.GET_CONVERSATION_LIST:
          return {
            ...state,
            conversationList: action.payload.data,
          };
        case ChatsActionTypes.ON_SEND_MESSAGE:
          return {
            ...state,
            isUserMessageSent: true,
          };
        case ChatsActionTypes.RECEIVE_MESSAGE:
        case ChatsActionTypes.RECEIVE_MESSAGE_FROM_USER:
          return {
            ...state,
            chatUserConversations: action.payload.data,
          };
        case ChatsActionTypes.READ_MESSAGE:
          return {
            ...state,
            isMessageRead: true,
            chatUserConversations: action.payload.data,
          };
        case ChatsActionTypes.DELETE_MESSAGE:
          return {
            ...state,
            isMessageDeleted: true,
          };
        case ChatsActionTypes.GET_CHANNEL_DETAILS:
          return {
            ...state,
            chatUserDetails: { ...action.payload.data, isChannel: true },
            isChannelDetailsFetched: true,
            getUserDetailsLoading: false,
          };
        default:
          return { ...state };
      }

    case ChatsActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case ChatsActionTypes.GET_FAVOURITES:
          return {
            ...state,
            isFavouritesFetched: false,
            getFavouritesLoading: false,
          };
        case ChatsActionTypes.GET_DIRECT_MESSAGES:
          return {
            ...state,
            isDirectMessagesFetched: false,
            getDirectMessagesLoading: false,
          };
        case ChatsActionTypes.GET_CHANNELS:
          return {
            ...state,
            isChannelsFetched: false,
            getChannelsLoading: false,
          };
        case ChatsActionTypes.ADD_CONTACTS:
          return {
            ...state,
            isContactsAdded: false,
            addContactsLoading: false,
          };
        case ChatsActionTypes.CREATE_CHANNEL:
          return {
            ...state,
            isChannelCreated: false,
            createChannelLoading: false,
          };
        case ChatsActionTypes.GET_CHAT_USER_DETAILS:
          return {
            ...state,
            isUserDetailsFetched: false,
            getUserDetailsLoading: false,
          };
        case ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS:
          return {
            ...state,
            chatUserConversations: {},
            isUserConversationsFetched: false,
            getUserConversationsLoading: false,
            isUserMessageSent: false,
          };
        case ChatsActionTypes.GET_MESSAGES_HISTORIES:
          return {
            ...state,
            messagesHistories: {},
            getUserConversationsLoading: false,
          };
        case ChatsActionTypes.GET_CONVERSATION_LIST:
          return {
            ...state,
            conversationList: {},
          };
        case ChatsActionTypes.ON_SEND_MESSAGE:
          return {
            ...state,
            isUserMessageSent: false,
          };
        case ChatsActionTypes.DELETE_MESSAGE:
          return {
            ...state,
            isMessageDeleted: false,
          };
        case ChatsActionTypes.FORWARD_MESSAGE:
          return {
            ...state,
            isMessageForwarded: false,
          };
        case ChatsActionTypes.DELETE_USER_MESSAGES:
          return {
            ...state,
            isUserMessagesDeleted: false,
          };
        case ChatsActionTypes.GET_CHANNEL_DETAILS:
          return {
            ...state,
            isChannelDetailsFetched: false,
            getUserDetailsLoading: false,
          };
        case ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT:
          return {
            ...state,
            isFavouriteContactToggled: false,
          };
        case ChatsActionTypes.GET_ARCHIVE_CONTACT:
          return {
            ...state,
            isArchiveContactFetched: false,
          };
        case ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT:
          return {
            ...state,
            isContactArchiveToggled: false,
          };
        case ChatsActionTypes.READ_CONVERSATION:
          return {
            ...state,
            isRead: false,
          };
        case ChatsActionTypes.DELETE_IMAGE:
          return {
            ...state,
            isImageDeleted: true,
          };
        default:
          return { ...state };
      }

    case ChatsActionTypes.GET_FAVOURITES: {
      return {
        ...state,
        getFavouritesLoading: true,
        isFavouritesFetched: false,
      };
    }
    case ChatsActionTypes.GET_DIRECT_MESSAGES:
      return {
        ...state,
        isDirectMessagesFetched: false,
        getDirectMessagesLoading: true,
      };
    case ChatsActionTypes.GET_CHANNELS:
      return {
        ...state,
        isChannelsFetched: false,
        getChannelsLoading: true,
      };
    case ChatsActionTypes.GET_CONVERSATION_LIST:
      return {
        ...state,
      };
    case ChatsActionTypes.ADD_CONTACTS:
      return {
        ...state,
        isContactsAdded: false,
        addContactsLoading: true,
      };
    case ChatsActionTypes.CREATE_CHANNEL:
      return {
        ...state,
        isChannelCreated: false,
        createChannelLoading: true,
      };
    case ChatsActionTypes.CHANGE_SELECTED_CHAT:
      return {
        ...state,
        selectedChat: action.payload,
      };
    case ChatsActionTypes.CHANGE_IS_LOAD_CONVERSATION:
      return {
        ...state,
        isLoadingConversationList: action.payload,
      };
    case ChatsActionTypes.CHANGE_REPLY_MSG_SELECTED:
      return {
        ...state,
        replyMsgSelected: action.payload,
      };
    case ChatsActionTypes.ADD_TAG_GROUP_SUCCESS:
      return {
        ...state,
        isAddTagGroupSuccess: action.payload,
      };
    case ChatsActionTypes.CHANGE_CHANNEL_SELECTED:
      return {
        ...state,
        channelSelected: action.payload,
      };
    case ChatsActionTypes.CHANGE_CONVERSATION_SELECTED:
      return {
        ...state,
        conversationSelected: action.payload,
      };
    case ChatsActionTypes.CHANGE_SELECTED_CONVERSATION:
      return {
        ...state,
        selectedConversation: action.payload,
      };
    case ChatsActionTypes.CHANGE_GROUP_INFO:
      return {
        ...state,
        groupInfo: action.payload,
      };
    case ChatsActionTypes.CHANGE_PAGE_INFO:
      return {
        ...state,
        pageInfo: action.payload,
      };
    case ChatsActionTypes.CHANGE_CONVERSATION_FILTER_STATE:
    return {
      ...state,
      conversationFilterState: action.payload,
    };

    case ChatsActionTypes.GET_CHAT_USER_DETAILS:
      return {
        ...state,
        isUserDetailsFetched: false,
        getUserDetailsLoading: true,
      };
    case ChatsActionTypes.GET_CHAT_USER_CONVERSATIONS:
      return {
        ...state,
        isUserConversationsFetched: false,
        getUserConversationsLoading: true,
        isUserMessageSent: false,
      };
    case ChatsActionTypes.GET_MESSAGES_HISTORIES:
      return {
        ...state,
        getUserConversationsLoading: true,
      };
    case ChatsActionTypes.TOGGLE_USER_DETAILS_TAB:
      return {
        ...state,
        isOpenUserDetails: action.payload,
      };
    case ChatsActionTypes.ON_SEND_MESSAGE:
      return {
        ...state,
        isUserMessageSent: false,
      };
    case ChatsActionTypes.DELETE_MESSAGE:
      return {
        ...state,
        isMessageDeleted: false,
      };
    case ChatsActionTypes.FORWARD_MESSAGE:
      return {
        ...state,
        isMessageForwarded: false,
      };
    case ChatsActionTypes.DELETE_USER_MESSAGES:
      return {
        ...state,
        isUserMessagesDeleted: false,
      };
    case ChatsActionTypes.GET_CHANNEL_DETAILS:
      return {
        ...state,
        isChannelDetailsFetched: false,
        getUserDetailsLoading: true,
      };
    case ChatsActionTypes.TOGGLE_FAVOURITE_CONTACT:
      return {
        ...state,
        isFavouriteContactToggled: false,
      };
    case ChatsActionTypes.GET_ARCHIVE_CONTACT:
      return {
        ...state,
        isArchiveContactFetched: false,
      };
    case ChatsActionTypes.TOGGLE_ARCHIVE_CONTACT:
      return {
        ...state,
        isContactArchiveToggled: false,
      };
    case ChatsActionTypes.DELETE_IMAGE:
      return {
        ...state,
        isImageDeleted: false,
      };
    case ChatsActionTypes.UPDATE_LIST_IMAGE_DRAFT:
      return {
        ...state,
        listImageDraft: action.payload,
      };
    case ChatsActionTypes.UPDATE_TAG_LIST:
      return {
        ...state,
        tagList: action.payload,
      };
    case ChatsActionTypes.REFRESH_CONVERSATION_LIST:
      return {
        ...state,
        refreshConversation: action.payload,
      };
    case ChatsActionTypes.MESSAGE_USER_READED:
      return {
        ...state,
        messagReadedInconverstaion: action.payload,
      };
    case ChatsActionTypes.UPDATE_REPLY_MESSAGE:
      return {
          ...state,
          replyMessage: action.payload,
      }
    case ChatsActionTypes.ON_HANDLE_STATUS_EXTENSION:
      return {
          ...state,
          extensionReady: action.payload,
      }

    case ChatsActionTypes.ONSEARCH_CONVERSATION:
      return {
          ...state,
          isSearchConversation: action.payload,
      }
    default:
      return { ...state };
  }
};

export default Chats;
